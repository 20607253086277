import React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';

import DemandImportForm from './DemandImportForm';
import DemandImportErrors from './DemandImportErrors';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { importDemands, resetDemandImport } from '../../../actions/DemandImportActions';
import { addGlobalError } from '../../../actions/SnackbarActions';

const DemandImport = ({ title, resultIntro, instructions, url, doImport, reset, loading, addError, result }) => (
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <DemandImportForm
        title={title}
        url={url}
        doImport={doImport}
        reset={reset}
        loading={loading}
        addError={addError}
      />
    </Grid>
    {result ? (
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h2">Résultat de l'import</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">{resultIntro(result.success)}</Typography>
              </Grid>
            </Grid>
          </CardContent>
          {Boolean(result.errors?.length) && <DemandImportErrors errors={result.errors} />}
        </Card>
      </Grid>
    ) : (
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid item xs={12}>
              <Typography variant="h2">Instructions</Typography>
            </Grid>
            <Grid item xs={12}>
              {instructions}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    )}
  </Grid>
);

DemandImport.propTypes = {
  title: PropTypes.string.isRequired,
  resultIntro: PropTypes.func.isRequired,
  instructions: PropTypes.node,
};

const mapStateToProps = ({ demandImport: { loading, result } }) => ({
  loading,
  result,
});

const mapDispatchToProps = {
  doImport: importDemands,
  reset: resetDemandImport,
  addError: addGlobalError,
};

export default connect(mapStateToProps, mapDispatchToProps)(DemandImport);
