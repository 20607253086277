import React from 'react';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import dayjs from 'dayjs';
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Formsy, { addValidationRule } from 'formsy-react';
import { connect } from 'react-redux';
import { Minus, Plus } from 'mdi-material-ui';
import classNames from 'classnames';

import {
  addAttachments,
  deleteAttachment,
  loadFDD,
  loadFDDTotal,
  resetFDD,
  saveOrUpdateFDD,
} from '../../actions/fdd/FDDActions';
import {
  AutocompleteField,
  CardHeader,
  DatePickerField,
  LabelValue,
  LoadingMessage,
  ResponsiveTable,
  TextField,
  ToggleSwitch,
} from '../utils';
import { hasRole } from '../../services/SecurityService';
import roles from '../../constants/roles';
import { loadOutlet } from '../commons/OutletAutocompleteField';
import DateViewer from '../commons/DateViewer';
import {
  FDD_PRODUCTS,
  FDD_STATUSES,
  fddLockedPaymentStatuses,
  validatingFDDStatuses,
} from '../../constants/AppConstants';
import { addGlobalError } from '../../actions/SnackbarActions';
import OutletCardContent from '../outlets/OutletCardContent';
import FDDButtons from './FDDButtons';
import DateTimeViewer from '../commons/DateTimeViewer';
import fddTransitions from '../../constants/fddTransitions';
import { formatCustomCurrency, formatRoundedCurrency } from '../../utils/currency-utils';
import {
  addMaxValidationRule,
  addMinValidationRule,
  addOutletExistsValidationRule,
} from '../../utils/validation-rules';
import CheckboxField from '../utils/CheckboxField';
import AttachementZoneField from '../utils/AttachmentZoneField';
import { withPageTitle } from '../../utils/page-title';
import { canUserEditTracking } from './fddUtils';
import {
  AgencyAutocompleteField,
  AreaAutocompleteField,
  FDDAgreementTypeAutocompleteField,
  FDDGoalAutocompleteField,
  FDDSubGoalAutocompleteField,
  RegionAutocompleteField,
} from '../commons/autocompleteFields';
import { stickyActionBar } from '../utils/commonStyles';

const styles = {
  fieldListItemContainer: {
    justifyContent: 'middle',
  },
  fieldListItemField: {
    flex: 1,
  },
  actionCol: {
    width: 0,
  },
  stickyActionBar,
  paymentColumn: {
    backgroundColor: '#F0F0FF',
    'tbody > tr:nth-child(2n+1) > &': {
      backgroundColor: '#E8E8F8',
    },
  },
  validationColumn: {
    backgroundColor: '#F0F8F8',
    'tbody > tr:nth-child(2n+1) > &': {
      backgroundColor: '#E8F0F0',
    },
  },
  total: {
    '&:first-child': {
      backgroundColor: '#F8E6FF',
    },
    '& > $paymentColumn': {
      backgroundColor: '#E9E3FF',
    },
  },
  subTotal: {
    '& > td': {
      fontWeight: 'normal',
      fontStyle: 'italic',
    },
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
};

const errorMessages = {
  amount: {
    isDefaultRequiredValue: 'Veuillez saisir un montant',
    isExisty: 'Veuillez saisir un montant',
    isInt: 'Veuillez saisir un montant entier',
    min: 'Le montant minimum est 0€',
    max: 'Le montant maximum est 99999 k€',
  },
  number: {
    isDefaultRequiredValue: 'Veuillez saisir un nombre',
    isExisty: 'Veuillez saisir un nombre',
    isInt: 'Veuillez saisir un nombre entier',
    min: 'Le nombre minimum est 0',
    max: 'Le nombre maximum est 99',
  },
  goal: {
    isDefaultRequiredValue: "L'objectif est obligatoire",
    isExisty: "L'objectif est obligatoire",
  },
  subGoal: {
    isDefaultRequiredValue: 'Le sous-objectif est obligatoire',
    isExisty: 'Le sous-objectif est obligatoire',
  },
  agreementTypes: {
    isDefaultRequiredValue: "Veuillez sélectionner au moins une nature d'accord",
    isExisty: "Veuillez sélectionner au moins une nature d'accord",
    minLength: "Veuillez sélectionner au moins une nature d'accord",
  },
  agreementType: {
    isDefaultRequiredValue: "Veuillez sélectionner une nature d'accord",
    isExisty: "Veuillez sélectionner une nature d'accord",
  },
  area: {
    isDefaultRequiredValue: 'Le secteur est obligatoire',
    isExisty: 'Le secteur est obligatoire',
  },
  attachments: {
    notEmptyIfPaymentValidated: 'Pièces jointes obligatoires pour pouvoir valider des versements.',
  },
  newOutlet: {
    falseIfPaymentValidated: 'Saisissez le point de vente pour pouvoir valider des versements',
  },
  goalDateTime: {
    isExisty: 'Veuillez saisir une date',
  },
};

const emptyPayment = (fddAgreementTypeIds) => ({
  agreementTypeId: fddAgreementTypeIds && fddAgreementTypeIds.length === 1 ? fddAgreementTypeIds[0] : null,
  date: null,
  amount: null,
  condition: '',
  invoiceReceivedDate: null,
});

const monthFormatter = (value) => value && value.startOf('month').format();
const dayFormatter = (value) => value && value.startOf('day').format();

const hasNoValidatedPayment = (values) =>
  Object.entries(values).filter(([k, v]) => k.startsWith('payment[') && k.endsWith('].paymentValidated') && Boolean(v))
    .length === 0;

const validateNotEmptyIfPaymentValidated = (values, value) => value.length > 0 || hasNoValidatedPayment(values);

const validateFalseIfPaymentValidated = (values, value) => !value || hasNoValidatedPayment(values);

class FDD extends React.Component {
  static propTypes = {
    fdd: PropTypes.object.isRequired,
    id: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    serverError: PropTypes.string,
    number: PropTypes.number,
    total: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      editing: !props.id,
      outletCode: '',
      outlet: null,
      regionId: null,
      agencyId: null,
      areaId: null,
      newOutlet: false,
      outletRevenue: null,
      agreementTypeIds: [],
      goalId: null,
      subGoalId: null,
      amount: null,
      payments: [emptyPayment()],
      ideas: [''],
      comment: '',
      validationDate: null,
      signingDate: null,
      observation: '',
      done: false,
      additionalAmount: null,
      additionalEquipments: '',
      argumentsContent: '',
      goalRevenue: null,
      goalProduct: null,
      goalStudyStartDateTime: null,
      goalStudyEndDateTime: null,
    };

    addOutletExistsValidationRule();
    addMinValidationRule();
    addMaxValidationRule();
    addValidationRule('notEmptyIfPaymentValidated', validateNotEmptyIfPaymentValidated);
    addValidationRule('falseIfPaymentValidated', validateFalseIfPaymentValidated);
  }

  componentDidMount() {
    const { dispatch, id } = this.props;

    /*
     * If no id (for new fdd), then loadFDD will reset the data in Redux.
     */
    dispatch(loadFDD(id));
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      id: newIdParam,
      fdd: { id },
      validationErrorsCounter,
      validationErrors,
      dispatch,
    } = nextProps;
    const {
      id: oldIdParam,
      fdd: { id: oldId, outletCode: oldOutletCode },
      validationErrorsCounter: oldValidationErrorsCounter,
    } = this.props;

    /**
     * If the url changes, load the new fdd
     */
    if (newIdParam !== oldIdParam) {
      dispatch(loadFDD(newIdParam));
      return;
    }

    /*
     * If the id changed, the form state is reset.
     */
    if (id !== oldId) {
      this.resetFormState(nextProps.fdd, oldOutletCode);
      if (id) {
        this.setState({ editing: false });
      }
    }

    /*
     * If the server invalidates some fields, we need to update the form.
     */
    if (validationErrorsCounter !== oldValidationErrorsCounter) {
      const errors = {};
      validationErrors.forEach((error) => {
        if (error.field && errors[error.field]) {
          errors[error.field] = error.defaultMessage;
        } else {
          dispatch(addGlobalError(error.defaultMessage));
        }
      });
      this.form.current.updateInputsWithError(errors);
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetFDD());
  }

  onActivateEditingMode = () => {
    this.setState({ editing: true });
  };

  onCancel = () => {
    const { fdd } = this.props;
    const { outletCode } = this.state;
    this.resetFormState(fdd, outletCode);
    this.setState({ editing: false });
    this.form.current.reset();
  };

  onChangeEvent = (fieldName) => (event) => {
    const { value } = event.target;
    this.setState({ [fieldName]: value });
  };

  onChangeInArray = (fieldName, idx) => (event) => {
    const { value } = event.target;
    this.setState((oldState) => {
      const array = [...oldState[fieldName]];
      array[idx] = value;
      return { [fieldName]: array };
    });
  };

  onChange = (fieldName) => (value) => {
    this.setState({ [fieldName]: value });
  };

  onChangeFDDAgreementType = (agreementTypeIds) => {
    const computeAgreementTypeId = (payment) => {
      if (agreementTypeIds.length === 1) {
        return agreementTypeIds[0];
      }
      if (!agreementTypeIds.includes(payment.agreementTypeId)) {
        // Reset payment agreement type if incompatible
        return null;
      }
      return payment.agreementTypeId;
    };

    const updatePaymentIfNeeded = (payment) => {
      const agreementTypeId = computeAgreementTypeId(payment);
      if (agreementTypeId === payment.agreementTypeId) {
        // Keep the payment object identity if possible, to help React optimize re-renders
        return payment;
      }
      return {
        ...payment,
        agreementTypeId,
      };
    };

    this.setState(({ payments }) => ({
      agreementTypeIds,
      payments: payments.map(updatePaymentIfNeeded),
    }));
  };

  onChangeNewOutlet = (newOutlet) => {
    // Reset outlet and area when switching from existing/new outlet
    const { dispatch, userRegionId, userAgencyId } = this.props;
    const newState = { newOutlet, outlet: null, outletCode: '', regionId: null, agencyId: null, areaId: null };
    if (newOutlet) {
      newState.outletRevenue = 0;
      newState.regionId = userRegionId;
      newState.agencyId = userAgencyId;
    }
    this.setState(newState);
    dispatch(loadFDDTotal(null));
  };

  onChangeRegion = (regionId) => {
    this.setState({ regionId, agencyId: null, areaId: null });
  };

  onChangeAgency = (agencyId, agency) => {
    const newState = { agencyId, areaId: null };
    if (agency && agency.region) {
      newState.regionId = agency.region.id;
    }
    this.setState(newState);
  };

  onChangeArea = (areaId, area) => {
    const newState = { areaId };
    if (area && area.agency) {
      newState.agencyId = area.agency.id;
    }
    if (area && area.region) {
      newState.regionId = area.region.id;
    }
    this.setState(newState);
  };

  onOutletChange = (outletCode, outlet) => {
    const { dispatch } = this.props;
    this.setState({
      outletCode,
      outlet,
    });
    dispatch(loadFDDTotal(outlet?.id, dayjs(outlet?.creationDate).year()));
  };

  onArrayAdd = (fieldName) => () => {
    this.setState((oldState) => ({ [fieldName]: [...oldState[fieldName], ''] }));
  };

  onArrayRemove = (fieldName, idx) => () => {
    this.setState((oldState) => {
      const array = [...oldState[fieldName]];
      array.splice(idx, 1);
      return { [fieldName]: array };
    });
  };

  onChangePayment = (idx, fieldName) => (value) => {
    this.setState((oldState) => {
      const payments = [...oldState.payments];
      // noinspection JSValidateTypes
      payments[idx] = {
        ...payments[idx],
        [fieldName]: value,
      };
      return { payments };
    });
  };

  onAddPayment = () => {
    this.setState(({ payments, agreementTypeIds }) => ({ payments: [...payments, emptyPayment(agreementTypeIds)] }));
  };

  onRemovePayment = (idx) => () => {
    this.setState((oldState) => {
      const payments = [...oldState.payments];
      payments.splice(idx, 1);
      return { payments };
    });
  };

  onAddAttachment = (acceptedFiles, rejectedFiles) => {
    const { dispatch } = this.props;
    dispatch(addAttachments(acceptedFiles, rejectedFiles));
  };

  onRemoveAttachment = (attachmentId, attachmentName) => {
    const { dispatch } = this.props;
    dispatch(deleteAttachment(attachmentId, attachmentName));
  };

  onSave = () => {
    this.form.current.submit();
  };

  onValidSubmit = () => {
    const {
      outlet,
      areaId,
      outletRevenue,
      goalId,
      subGoalId,
      agreementTypeIds,
      amount,
      payments,
      ideas,
      comment,
      validationDate,
      signingDate,
      observation,
      done,
      argumentsContent,
      additionalAmount,
      additionalEquipments,
      goalRevenue,
      goalProduct,
      goalStudyStartDateTime,
      goalStudyEndDateTime,
    } = this.state;

    const { dispatch, id, number } = this.props;

    dispatch(
      saveOrUpdateFDD({
        id,
        outletId: outlet && outlet.id,
        areaId,
        outletRevenue,
        goal: goalId && { id: goalId },
        subGoal: subGoalId && { id: subGoalId },
        agreementTypes: agreementTypeIds.map((agreementTypeId) => ({ id: agreementTypeId })),
        amount,
        payments: payments.map((payment) => ({
          ...payment,
          agreementType: payment.agreementTypeId && { id: payment.agreementTypeId },
        })),
        ideas,
        comment,
        validationDate,
        signingDate,
        observation,
        done,
        number,
        additionalAmount,
        additionalEquipments,
        argumentsContent,
        goalRevenue,
        goalProduct,
        goalStudyStartDateTime,
        goalStudyEndDateTime,
      }),
    );
  };

  onInvalidSubmit = () => {
    const { dispatch } = this.props;
    dispatch(addGlobalError('Le formulaire contient des erreurs'));
  };

  resetFormState(fdd, oldOutletCode) {
    const {
      outletCode = null,
      regionId = null,
      agencyId = null,
      areaId = null,
      outletRevenue = null,
      goal = null,
      subGoal = null,
      agreementTypes = [],
      amount = null,
      payments = [emptyPayment()],
      ideas = [''],
      comment = null,
      validationDate = null,
      signingDate = null,
      observation = null,
      done = false,
      additionalAmount = null,
      additionalEquipments = '',
      argumentsContent = '',
      goalRevenue = null,
      goalProduct = null,
      goalStudyStartDateTime = null,
      goalStudyEndDateTime = null,
    } = fdd;

    // noinspection JSObjectNullOrUndefined
    this.setState({
      outletCode,
      regionId,
      agencyId,
      areaId,
      newOutlet: Boolean(!outletCode && areaId),
      outletRevenue,
      goalId: goal?.id,
      subGoalId: subGoal?.id,
      agreementTypeIds: agreementTypes.map((agreementType) => agreementType.id),
      amount,
      payments: payments.map((payment) => ({
        ...payment,
        agreementTypeId: payment.agreementType && payment.agreementType.id,
      })),
      ideas,
      comment,
      validationDate,
      signingDate,
      observation,
      done,
      additionalAmount,
      additionalEquipments,
      argumentsContent,
      goalRevenue,
      goalProduct,
      goalStudyStartDateTime,
      goalStudyEndDateTime,
    });

    if (outletCode !== oldOutletCode) {
      if (outletCode) {
        loadOutlet(outletCode).then((outlet) => {
          if (outlet) {
            this.setState({
              outletCode,
              outlet,
            });
          }
        });
      } else {
        this.setState({ outlet: null });
      }
    }
  }

  render() {
    const {
      id,
      fdd: {
        number,
        creationDate,
        openedDate,
        attachments,
        status,
        events,
        amount: originalAmount,
        user,
        lastModifiedDate,
      },
      total,
      loading,
      serverError,
      classes,
      canEditForm,
      canEditPayment,
      canValidatePayment,
      canEditTracking,
      canEditAssessment,
      canEditAttachment,
      canFillInOutlet,
      userRegionId,
      userAgencyId,
      dispatch,
    } = this.props;
    const {
      editing,
      outletCode,
      outlet,
      regionId,
      agencyId,
      areaId,
      newOutlet,
      outletRevenue,
      goalId,
      subGoalId,
      agreementTypeIds,
      amount,
      payments,
      ideas,
      comment,
      validationDate,
      signingDate,
      observation,
      done,
      additionalAmount,
      additionalEquipments,
      argumentsContent,
      goalRevenue,
      goalProduct,
      goalStudyStartDateTime,
      goalStudyEndDateTime,
    } = this.state;

    if (loading || serverError) {
      return (
        <LoadingMessage loading={loading} serverError={serverError}>
          Fonds de développement
        </LoadingMessage>
      );
    }

    const paymentsTotal = payments
      .map((payment) => parseFloat(payment.amount) || 0)
      .reduce((accumulator, currentValue) => accumulator + currentValue);
    const correctPayments = Math.abs(paymentsTotal - amount) < 0.01;
    const maxPaymentDate = dayjs.max(payments.filter((payment) => payment.date).map((payment) => dayjs(payment.date)));

    const yearStart = dayjs().startOf('year').format('YYYY-MM-DD');
    const yearEnd = dayjs().endOf('year').format('YYYY-MM-DD');
    const openedYear = dayjs(openedDate ?? undefined).year();

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h1">
            {id
              ? `${outlet ? `${outlet.code} - ${outlet.name} - ` : ''} Fonds de développement - ${number}`
              : `Nouveau dossier de fonds de développement`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Formsy noValidate ref={this.form} onValidSubmit={this.onValidSubmit} onInvalidSubmit={this.onInvalidSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} container>
                <Card>
                  <CardHeader>Point de vente</CardHeader>
                  <CardContent>
                    <Grid container spacing={2}>
                      {(newOutlet || (editing && (canEditForm || canFillInOutlet))) && (
                        <Grid item xs={12}>
                          <CheckboxField
                            name="newOutlet"
                            disabled={!editing || (!canEditForm && !canFillInOutlet)}
                            label="Nouveau point de vente inconnu de Prestago"
                            value={newOutlet}
                            onChange={this.onChangeNewOutlet}
                            validations="falseIfPaymentValidated"
                            validationErrors={errorMessages.newOutlet}
                          />
                        </Grid>
                      )}

                      {newOutlet ? (
                        <>
                          <Grid item xs={12}>
                            <RegionAutocompleteField
                              disabled={!editing || !canEditForm || userRegionId}
                              name="regionId"
                              value={regionId}
                              label="Région"
                              onChange={this.onChangeRegion}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <AgencyAutocompleteField
                              disabled={!editing || !canEditForm || userAgencyId}
                              name="agencyId"
                              value={agencyId}
                              label="Zone de vente"
                              onChange={this.onChangeAgency}
                              regionIds={regionId && [regionId]}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <AreaAutocompleteField
                              disabled={!editing || !canEditForm}
                              name="areaId"
                              value={areaId}
                              label="Secteur"
                              onChange={this.onChangeArea}
                              required
                              validations="isExisty"
                              validationErrors={errorMessages.area}
                              regionIds={regionId && [regionId]}
                              agencyIds={agencyId && [agencyId]}
                            />
                          </Grid>
                        </>
                      ) : (
                        <OutletCardContent
                          onOutletChange={this.onOutletChange}
                          editing={editing && (canEditForm || canFillInOutlet)}
                          multiOutlet={false}
                          outletCode={outletCode}
                          outlet={outlet}
                          id={id ? id : null}
                          hideTitle
                        />
                      )}

                      <Grid item xs={12}>
                        {editing && canEditForm ? (
                          <TextField
                            type="number"
                            label="Chiffre d'affaires (A-1)"
                            fullWidth
                            name="outletRevenue"
                            onChange={this.onChangeEvent('outletRevenue')}
                            required
                            min="0"
                            max="99999"
                            validations="isInt,min:0,max:99999"
                            validationErrors={errorMessages.amount}
                            value={outletRevenue}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">k€</InputAdornment>,
                            }}
                          />
                        ) : (
                          <LabelValue label="Chiffre d'affaires (A-1)">
                            {formatCustomCurrency(outletRevenue, 'k€')}
                          </LabelValue>
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={6} container>
                <Card>
                  <CardHeader>Informations générales</CardHeader>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <LabelValue label="Statut">{FDD_STATUSES.getNameById(status)}</LabelValue>
                      </Grid>

                      {id && creationDate && (
                        <Grid item xs={12}>
                          <LabelValue label="Date de saisie">
                            <DateViewer date={creationDate} />
                          </LabelValue>
                        </Grid>
                      )}

                      <Grid item xs={12}>
                        <LabelValue label="Millésime">{openedYear}</LabelValue>
                      </Grid>

                      <Grid item xs={12}>
                        <FDDGoalAutocompleteField
                          disabled={!editing || !canEditForm}
                          name="goalId"
                          value={goalId}
                          label="Objectif"
                          onChange={this.onChange('goalId')}
                          clearable
                          required
                          validations="isExisty"
                          validationErrors={errorMessages.goal}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <FDDSubGoalAutocompleteField
                          disabled={!editing || !canEditForm}
                          name="subGoalId"
                          value={subGoalId}
                          label="Sous-objectif"
                          onChange={this.onChange('subGoalId')}
                          clearable
                          required={editing && canEditForm}
                          validations={editing && canEditForm && 'isExisty'}
                          validationErrors={errorMessages.subGoal}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <FDDAgreementTypeAutocompleteField
                          disabled={!editing || !canEditForm}
                          name="agreementTypeIds"
                          value={agreementTypeIds}
                          label="Natures d'accord"
                          onChange={this.onChangeFDDAgreementType}
                          required
                          validations="isExisty,minLength:1"
                          validationErrors={errorMessages.agreementTypes}
                          multiple
                        />
                      </Grid>

                      <Grid item xs={12}>
                        {editing && canEditForm ? (
                          <TextField
                            type="number"
                            label="Montant demandé"
                            fullWidth
                            name="amount"
                            onChange={this.onChangeEvent('amount')}
                            required
                            min="0"
                            validations="isExisty,isInt,min:0"
                            validationErrors={errorMessages.amount}
                            value={amount}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">€</InputAdornment>,
                            }}
                          />
                        ) : (
                          <LabelValue label="Montant demandé">{formatRoundedCurrency(amount)}</LabelValue>
                        )}
                      </Grid>

                      {!newOutlet && (
                        <Grid item xs={12}>
                          <LabelValue label={`Total alloué en ${openedYear} pour le PDV ${outletCode}`}>
                            {formatRoundedCurrency(total - (originalAmount || 0) + (parseFloat(amount) || 0))}
                          </LabelValue>
                        </Grid>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} container>
                <Card>
                  <CardHeader>Dossier</CardHeader>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h3">Arguments</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          multiline
                          disabled={!editing || !canEditForm}
                          label="Arguments"
                          fullWidth
                          name="argumentsContent"
                          onChange={this.onChangeEvent('argumentsContent')}
                          value={argumentsContent}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="h3">Projet détaillé</Typography>
                      </Grid>

                      {ideas.map((idea, idx) => (
                        <Grid item xs={12} key={idx}>
                          <Grid container spacing={2} className={classes.fieldListItemContainer}>
                            <Grid item className={classes.fieldListItemField}>
                              <TextField
                                multiline
                                disabled={!editing || !canEditForm}
                                label={`Idée ${idx + 1}`}
                                fullWidth
                                name={`ideas[${idx}]`}
                                onChange={this.onChangeInArray('ideas', idx)}
                                value={idea}
                              />
                            </Grid>
                            {editing && canEditForm && (
                              <Grid item>
                                {idx === ideas.length - 1 ? (
                                  <Tooltip title="Ajouter une idée">
                                    <IconButton onClick={this.onArrayAdd('ideas')} size="large">
                                      <Plus />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="Supprimer cette idée">
                                    <IconButton onClick={this.onArrayRemove('ideas', idx)} size="large">
                                      <Minus />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      ))}

                      <Grid item xs={12}>
                        <Typography variant="h3">Mesure du ROI</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {editing && canEditForm ? (
                          <TextField
                            type="number"
                            required
                            label={'Enjeux additionnel'}
                            name="additionalAmount"
                            onChange={this.onChangeEvent('additionalAmount')}
                            value={additionalAmount}
                            min="0"
                            validations="isExisty,isInt,min:0"
                            validationErrors={errorMessages.amount}
                            InputProps={{
                              endAdornment: <InputAdornment position="end">€</InputAdornment>,
                            }}
                          />
                        ) : (
                          <LabelValue label="Montant">{formatCustomCurrency(additionalAmount, '€')}</LabelValue>
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="h3">Objectifs</Typography>
                      </Grid>
                      <Grid item xs={3}>
                        {editing && canEditForm ? (
                          <Tooltip title="La saisie du CA à réaliser permet de suivre la progression du FDD dans PMU&Nous">
                            <Box>
                              <TextField
                                type="number"
                                label="CA à réaliser"
                                fullWidth
                                name="goalRevenue"
                                onChange={this.onChangeEvent('goalRevenue')}
                                min="0"
                                validations="isInt,min:0"
                                validationErrors={errorMessages.amount}
                                value={goalRevenue}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                }}
                              />
                            </Box>
                          </Tooltip>
                        ) : (
                          <LabelValue label="CA à réaliser">{formatCustomCurrency(goalRevenue, '€')}</LabelValue>
                        )}
                      </Grid>
                      <Grid item xs={3}>
                        {editing && canEditForm ? (
                          <AutocompleteField
                            options={FDD_PRODUCTS}
                            label="Produit"
                            fullWidth
                            name="goalProduct"
                            value={goalProduct}
                            onChange={this.onChange('goalProduct')}
                          />
                        ) : (
                          <LabelValue label="Produit">{FDD_PRODUCTS.getNameById(goalProduct)}</LabelValue>
                        )}
                      </Grid>
                      <Grid item xs={3}>
                        <DatePickerField
                          disabled={!editing || !canEditForm}
                          label="Début de la période considérée"
                          fullWidth
                          name="goalStudyStartDateTime"
                          onChange={this.onChange('goalStudyStartDateTime')}
                          value={goalStudyStartDateTime}
                          convertDateToString={dayFormatter}
                          required
                          validationError={errorMessages.goalDateTime.isExisty}
                          clearable
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <DatePickerField
                          disabled={!editing || !canEditForm}
                          label="Fin de la période considérée"
                          fullWidth
                          name="goalStudyEndDateTime"
                          onChange={this.onChange('goalStudyEndDateTime')}
                          value={goalStudyEndDateTime}
                          convertDateToString={dayFormatter}
                          required
                          validationError={errorMessages.goalDateTime.isExisty}
                          clearable
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          multiline
                          disabled={!editing || !canEditForm}
                          label="Ajout d'équipement non valorisé dans l'accord"
                          fullWidth
                          name="additionalEquipments"
                          onChange={this.onChangeEvent('additionalEquipments')}
                          value={additionalEquipments}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} container>
                <Card>
                  <CardHeader>Échéancier de versement</CardHeader>
                  <ResponsiveTable>
                    <TableHead>
                      <TableRow>
                        <TableCell>Mois initial de versement</TableCell>
                        <TableCell>Montant</TableCell>
                        <TableCell>Nature</TableCell>
                        <TableCell>Motifs de déblocage</TableCell>
                        <TableCell>Date réception facture si travaux</TableCell>
                        <TableCell className={classes.paymentColumn}>Mois de versement</TableCell>
                        <TableCell className={classes.paymentColumn}>Montant versé</TableCell>
                        <TableCell className={classes.validationColumn}>Paiement validé</TableCell>
                        {editing && canEditForm && <TableCell className={classes.actionCol} />}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {payments.map((payment, idx) => (
                        <TableRow key={idx}>
                          <TableCell>
                            <DatePickerField
                              disabled={!editing || !canEditForm || fddLockedPaymentStatuses.includes(status)}
                              fullWidth
                              name={`payments[${idx}].date`}
                              onChange={this.onChangePayment(idx, 'date')}
                              value={payment.date}
                              views={['year', 'month']}
                              format="MMMM YYYY"
                              convertDateToString={monthFormatter}
                              clearable
                              defaultCalendarMonth={maxPaymentDate}
                            />
                          </TableCell>
                          <TableCell className={classes.noWrap}>
                            {editing && canEditForm && !fddLockedPaymentStatuses.includes(status) ? (
                              <TextField
                                type="number"
                                fullWidth
                                name={`payments[${idx}].amount`}
                                onChange={(event) => this.onChangePayment(idx, 'amount')(event.target.value)}
                                min="0"
                                validations="isExisty,isInt,min:0"
                                validationErrors={errorMessages.amount}
                                value={payment.amount}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                }}
                              />
                            ) : (
                              formatRoundedCurrency(payment.amount)
                            )}
                          </TableCell>
                          <TableCell>
                            <FDDAgreementTypeAutocompleteField
                              disabled={!editing || !canEditForm || fddLockedPaymentStatuses.includes(status)}
                              name={`payments[${idx}].agreementTypeId`}
                              value={payment.agreementTypeId}
                              onChange={this.onChangePayment(idx, 'agreementTypeId')}
                              whiteList={agreementTypeIds}
                              validations="isExisty"
                              validationErrors={errorMessages.agreementType}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              multiline
                              disabled={!editing || !canEditForm || fddLockedPaymentStatuses.includes(status)}
                              fullWidth
                              name={`payments[${idx}].condition`}
                              onChange={(event) => this.onChangePayment(idx, 'condition')(event.target.value)}
                              value={payment.condition}
                            />
                          </TableCell>
                          <TableCell>
                            <DatePickerField
                              disabled={!editing || (!canEditForm && !canEditTracking)}
                              fullWidth
                              name={`payments[${idx}].invoiceReceivedDate`}
                              onChange={this.onChangePayment(idx, 'invoiceReceivedDate')}
                              value={payment.invoiceReceivedDate}
                              clearable
                            />
                          </TableCell>
                          <TableCell className={classes.paymentColumn}>
                            <DatePickerField
                              disabled={!editing || !canEditPayment || payment.paymentValidated}
                              fullWidth
                              name={`payments[${idx}].paymentDate`}
                              onChange={this.onChangePayment(idx, 'paymentDate')}
                              value={payment.paymentDate}
                              views={['year', 'month']}
                              format="MMMM YYYY"
                              convertDateToString={monthFormatter}
                              clearable
                            />
                          </TableCell>
                          <TableCell className={classNames(classes.noWrap, classes.paymentColumn)}>
                            {editing && canEditPayment && !payment.paymentValidated ? (
                              <TextField
                                type="number"
                                fullWidth
                                name={`payments[${idx}].paymentAmount`}
                                onChange={(event) => this.onChangePayment(idx, 'paymentAmount')(event.target.value)}
                                min="0"
                                validations="isInt,min:0"
                                validationErrors={errorMessages.amount}
                                value={payment.paymentAmount}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                }}
                              />
                            ) : (
                              formatRoundedCurrency(payment.paymentAmount)
                            )}
                          </TableCell>
                          <TableCell className={classes.validationColumn}>
                            {editing && canValidatePayment ? (
                              <CheckboxField
                                name={`payments[${idx}].paymentValidated`}
                                value={payment.paymentValidated}
                                onChange={this.onChangePayment(idx, 'paymentValidated')}
                                disabled={
                                  !payment.paymentDate ||
                                  payment.paymentAmount === null ||
                                  payment.paymentAmount === undefined ||
                                  payment.paymentAmount === ''
                                }
                              />
                            ) : payment.paymentValidated ? (
                              'Oui'
                            ) : (
                              'Non'
                            )}
                          </TableCell>
                          {editing && canEditForm && (
                            <TableCell>
                              {idx === payments.length - 1 ? (
                                <Tooltip title="Ajouter un versement">
                                  <IconButton onClick={this.onAddPayment} size="large">
                                    <Plus />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <Tooltip title="Supprimer ce versement">
                                  <IconButton onClick={this.onRemovePayment(idx)} size="large">
                                    <Minus />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                    {!editing && payments.length && (
                      <TableFooter>
                        <TableRow className={classes.total}>
                          <TableCell>Total attribué initial</TableCell>
                          <TableCell className={classes.noWrap}>
                            {formatRoundedCurrency(
                              payments.map((payment) => payment.amount).reduce((acc, a) => acc + a, 0),
                            )}
                          </TableCell>
                          <TableCell colSpan={3} />
                          <TableCell className={classes.paymentColumn}>Total</TableCell>
                          <TableCell className={classNames(classes.noWrap, classes.paymentColumn)}>
                            {formatRoundedCurrency(
                              payments.map((payment) => payment.paymentAmount).reduce((acc, a) => acc + a, 0),
                            )}
                          </TableCell>
                          <TableCell />
                        </TableRow>
                        <TableRow className={classes.subTotal}>
                          <TableCell>Années antérieures</TableCell>
                          <TableCell className={classes.noWrap}>
                            {formatRoundedCurrency(
                              payments
                                .filter((payment) => payment.date < yearStart)
                                .map((payment) => payment.amount)
                                .reduce((acc, a) => acc + a, null),
                            )}
                          </TableCell>
                          <TableCell colSpan={3} />
                          <TableCell className={classes.paymentColumn}>Années antérieures</TableCell>
                          <TableCell className={classNames(classes.noWrap, classes.paymentColumn)}>
                            {formatRoundedCurrency(
                              payments
                                .filter((payment) => payment.paymentDate < yearStart)
                                .map((payment) => payment.paymentAmount)
                                .reduce((acc, a) => acc + a, null),
                            )}
                          </TableCell>
                          <TableCell />
                        </TableRow>
                        <TableRow className={classes.subTotal}>
                          <TableCell>Année en cours</TableCell>
                          <TableCell className={classes.noWrap}>
                            {formatRoundedCurrency(
                              payments
                                .filter((payment) => payment.date >= yearStart && payment.date <= yearEnd)
                                .map((payment) => payment.amount)
                                .reduce((acc, a) => acc + a, null),
                            )}
                          </TableCell>
                          <TableCell colSpan={3} />
                          <TableCell className={classes.paymentColumn}>Année en cours</TableCell>
                          <TableCell className={classNames(classes.noWrap, classes.paymentColumn)}>
                            {formatRoundedCurrency(
                              payments
                                .filter((payment) => payment.paymentDate >= yearStart && payment.paymentDate <= yearEnd)
                                .map((payment) => payment.paymentAmount)
                                .reduce((acc, a) => acc + a, null),
                            )}
                          </TableCell>
                          <TableCell />
                        </TableRow>
                        <TableRow className={classes.subTotal}>
                          <TableCell>Années suivantes</TableCell>
                          <TableCell className={classes.noWrap}>
                            {formatRoundedCurrency(
                              payments
                                .filter((payment) => payment.date > yearEnd)
                                .map((payment) => payment.amount)
                                .reduce((acc, a) => acc + a, null),
                            )}
                          </TableCell>
                          <TableCell colSpan={3} />
                          <TableCell className={classes.paymentColumn}>Années suivantes</TableCell>
                          <TableCell className={classNames(classes.noWrap, classes.paymentColumn)}>
                            {formatRoundedCurrency(
                              payments
                                .filter((payment) => payment.paymentDate > yearEnd)
                                .map((payment) => payment.paymentAmount)
                                .reduce((acc, a) => acc + a, null),
                            )}
                          </TableCell>
                          <TableCell />
                        </TableRow>
                      </TableFooter>
                    )}
                  </ResponsiveTable>
                  {!correctPayments && (
                    <CardContent>
                      <Typography variant="body2" color="error">
                        {paymentsTotal < amount
                          ? `Versements insuffisants (manque ${formatRoundedCurrency(amount - paymentsTotal)})`
                          : `Versements trop importants (${formatRoundedCurrency(paymentsTotal - amount)} de trop)`}
                      </Typography>
                    </CardContent>
                  )}
                </Card>
              </Grid>

              <Grid item xs={12} container>
                <Card>
                  <CardHeader>Pièces jointes</CardHeader>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        {id ? (
                          <AttachementZoneField
                            name="attachments"
                            value={Object.values(attachments || {})}
                            dispatch={dispatch}
                            onAdd={this.onAddAttachment}
                            onRemove={this.onRemoveAttachment}
                            readOnly={!editing || (!canEditForm && !canEditAttachment)}
                            validations="notEmptyIfPaymentValidated"
                            validationErrors={errorMessages.attachments}
                          />
                        ) : (
                          <Typography variant="body1">
                            Veuillez enregistrer avant d'ajouter des pièces jointes
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} container>
                <Card>
                  <CardHeader>Suivi</CardHeader>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          multiline
                          disabled={!editing || (!canEditForm && !canEditTracking)}
                          label="Commentaires"
                          fullWidth
                          name="comment"
                          onChange={this.onChangeEvent('comment')}
                          value={comment}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <DatePickerField
                          disabled={!editing || (!canEditForm && !canEditTracking)}
                          label="Date de l'accord"
                          fullWidth
                          name="validationDate"
                          onChange={this.onChange('validationDate')}
                          value={validationDate}
                          clearable
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <DatePickerField
                          disabled={!editing || (!canEditForm && !canEditTracking)}
                          label="Réception de la convention signée"
                          fullWidth
                          name="signingDate"
                          onChange={this.onChange('signingDate')}
                          value={signingDate}
                          clearable
                        />
                      </Grid>

                      <Grid item xs={10}>
                        <TextField
                          multiline
                          disabled={!editing || !canEditAssessment}
                          label="Bilan après période d’analyse"
                          fullWidth
                          name="observation"
                          onChange={this.onChangeEvent('observation')}
                          value={observation}
                        />
                      </Grid>

                      <Grid item xs={2}>
                        <ToggleSwitch
                          disabled={!editing || !canEditAssessment}
                          label="Bilan fait"
                          value={done}
                          onChange={this.onChange('done')}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              {id && events && (
                <Grid item xs={12} container>
                  <Card>
                    <CardHeader>Historique</CardHeader>
                    <CardContent>
                      <Grid container spacing={2}>
                        {user && lastModifiedDate && (
                          <Grid item xs={12}>
                            <Typography variant="body1">
                              Dernière modification le <DateViewer date={lastModifiedDate} /> par {user.firstName}{' '}
                              {user.lastName}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </CardContent>
                    <ResponsiveTable>
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Événement</TableCell>
                          <TableCell>Auteur</TableCell>
                          <TableCell>Message</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {events.map((event) => (
                          <TableRow key={`event-${event.createdDate}`}>
                            <TableCell>
                              <DateTimeViewer date={event.createdDate} />
                            </TableCell>
                            <TableCell>{fddTransitions.getNameById(event.transition)}</TableCell>
                            <TableCell>{event.user && `${event.user.firstName} ${event.user.lastName}`}</TableCell>
                            <TableCell className={classes.comment}>{event.comment}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </ResponsiveTable>
                  </Card>
                </Grid>
              )}
            </Grid>
          </Formsy>
        </Grid>
        <Grid item xs={12} className={classes.stickyActionBar}>
          <FDDButtons
            editing={editing}
            onActivateEditingMode={this.onActivateEditingMode}
            onCancel={this.onCancel}
            onSave={this.onSave}
            correctPayments={correctPayments}
            canEdit={canEditForm || canEditPayment || canValidatePayment || canEditAssessment || canEditAttachment}
            paymentsInForm={payments}
          />
        </Grid>
      </Grid>
    );
  }
}

const stateToProps = ({
  fdd: { fdd, loading, error: serverError, validationErrors, validationErrorsCounter, total },
  currentUser,
}) => {
  const fddStatus = fdd && fdd.status;
  return {
    fdd,
    total,
    loading,
    serverError,
    validationErrors,
    validationErrorsCounter,
    canEditForm:
      (fddStatus === '_00_DRAFT' && hasRole(currentUser, roles.fdd.edit.code)) ||
      (validatingFDDStatuses.includes(fddStatus) && hasRole(currentUser, roles.fdd.edit_bd.code)),
    canEditPayment: fddStatus === '_20_IN_PROGRESS' && hasRole(currentUser, roles.fdd.payment_edit.code),
    canValidatePayment: fddStatus === '_20_IN_PROGRESS' && hasRole(currentUser, roles.fdd.payment_validate.code),
    canEditTracking: canUserEditTracking(currentUser, fddStatus),
    canEditAssessment:
      (fddStatus === '_20_IN_PROGRESS' || fddStatus === '_30_CLOSED') &&
      hasRole(currentUser, roles.fdd.assessment_edit.code),
    canEditAttachment: fddStatus === '_20_IN_PROGRESS' && hasRole(currentUser, roles.fdd.attachment_edit.code),
    canFillInOutlet:
      fddStatus === '_20_IN_PROGRESS' && hasRole(currentUser, roles.fdd.edit_bd.code) && fdd && !fdd.outletId,
    userRegionId: currentUser.user.regionId || null,
    userAgencyId: currentUser.user.agencyId || null,
  };
};

export default compose(
  withStyles(styles),
  connect(stateToProps),
  withPageTitle(({ id, fdd }) => (id ? `FDD ${fdd?.number ?? ''}` : 'Nouveau FDD')),
)(FDD);
