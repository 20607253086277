import React from 'react';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import InterventionOperationsList from './InterventionOperationsList';
import InterventionOperationCommands from './InterventionOperationCommands';
import { getOperationsThatCanBeAdded } from '../../actions/InterventionOperationsActions';
import roles from '../../constants/roles';
import { hasRole } from '../../services/SecurityService';
import { canUserEditIntervention } from '../../utils/intervention-utils';
import { ORACLE_COMMAND_STATUSES } from '../../constants/AppConstants';
import { stickyActionBar } from '../utils/commonStyles';

export const styles = (theme) => ({
  columnOf2: {
    [theme.breakpoints.up('md')]: {
      '&:first-of-type': {
        paddingRight: theme.spacing(1),
      },
      '&:last-of-type': {
        paddingLeft: theme.spacing(1),
      },
    },
    [theme.breakpoints.down('md')]: {
      '&:first-of-type': {
        paddingBottom: theme.spacing(2),
      },
    },
  },
  columnOf3: {
    [theme.breakpoints.up('xl')]: {
      paddingLeft: 5,
      paddingRight: 5,
      '&:first-of-type': {
        paddingLeft: 0,
        paddingRight: 11,
      },
      '&:last-of-type': {
        paddingLeft: 11,
        paddingRight: 0,
      },
    },
    [theme.breakpoints.down('xl')]: {
      paddingBottom: theme.spacing(2),
      '&:last-of-type': {
        paddingBottom: 0,
      },
    },
  },
  stickyActionBar,
});

class InterventionOperations extends React.Component {
  static propTypes = {
    canEdit: PropTypes.bool,
    completionStatusCanBeToggled: PropTypes.bool,
    dispatch: PropTypes.func,
    interventionId: PropTypes.string,
    addOperations: PropTypes.array,
    moveOperations: PropTypes.array,
    removeOperations: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.state = {
      openedEquipmentId: null,
      openedEquipmentName: null,
    };
  }

  /**
   * Operations that the user can add are loaded
   */
  componentDidMount() {
    const { canEdit, dispatch, interventionId } = this.props;
    if (interventionId && canEdit) {
      dispatch(getOperationsThatCanBeAdded(interventionId));
    }
  }

  onOpenCommandDetails = (openedEquipmentId, openedEquipmentName) => {
    this.setState({
      openedEquipmentId,
      openedEquipmentName,
    });
  };

  onCloseCommandDetails = () => {
    this.setState({
      openedEquipmentId: null,
      openedEquipmentName: null,
    });
  };

  getOracleStatus = (equipmentId) => {
    const { oracleCommands } = this.props;
    if (!oracleCommands || !oracleCommands.length) {
      return null;
    }
    for (let i = oracleCommands.length - 1; i >= 0; i--) {
      const oracleCommand = oracleCommands[i];
      if (oracleCommand.equipments.some((eq) => eq.equipmentId === equipmentId)) {
        return ORACLE_COMMAND_STATUSES.getById(oracleCommand.status);
      }
    }
    return null;
  };

  getOracleCommands = (equipmentId) => {
    const { oracleCommands } = this.props;
    if (!equipmentId || !oracleCommands || !oracleCommands.length) {
      return [];
    }

    return oracleCommands
      .filter(({ equipments }) => equipments.some((eq) => eq.equipmentId === equipmentId))
      .map(({ index, deliveryToOutlet, deliveryLocationId, code, inclusionDate, status, equipments }) => ({
        index,
        deliveryToOutlet,
        deliveryLocationId,
        code,
        inclusionDate,
        status: ORACLE_COMMAND_STATUSES.getById(status),
        quantity: equipments.find((eq) => eq.equipmentId === equipmentId).quantity,
      }));
  };

  render() {
    const {
      canEdit,
      completionStatusCanBeToggled,
      dispatch,
      interventionId,
      addOperations,
      moveOperations,
      removeOperations,
      operationsThatCanBeAdded,
      multiOutlet,
      showDeliveryStatus,
      showAddDelivery,
      updateTabsHeight,
      classes,
    } = this.props;
    const { openedEquipmentId, openedEquipmentName } = this.state;

    if (!interventionId) {
      return null;
    }

    const showAdd =
      addOperations.some((op) => op.displayed && op.quantity > 0) ||
      (operationsThatCanBeAdded.addOperations.length > 0 && canEdit);
    const showRemove =
      removeOperations.some((op) => op.displayed && op.quantity > 0) ||
      (operationsThatCanBeAdded.removeOperations.length > 0 && canEdit);
    const showMove =
      moveOperations.some((op) => op.displayed && op.quantity > 0) ||
      (operationsThatCanBeAdded.moveOperations.length > 0 && canEdit);
    const nbLists = showAdd + showRemove + showMove;
    const md = nbLists === 3 ? 12 : 6; // Si 3 listes, on se rabat sur 1 colonne, sinon 2.
    const xl = nbLists === 3 ? 4 : 6; // 2 ou 3 colonnes, jamais une seule

    return (
      <>
        <Grid container>
          {showAdd && (
            <Grid item xs={12} md={md} xl={xl} className={classes[`columnOf${nbLists}`]}>
              <InterventionOperationsList
                canEdit={canEdit}
                completionStatusCanBeToggled={completionStatusCanBeToggled}
                dispatch={dispatch}
                interventionId={interventionId}
                operations={addOperations}
                readOnly={false}
                operationsThatCanBeAdded={operationsThatCanBeAdded.addOperations}
                operationType="add"
                suffix={multiOutlet ? '(PDV destination)' : ''}
                showDeliveryStatus={showDeliveryStatus}
                showAddDelivery={showAddDelivery}
                getOracleStatus={this.getOracleStatus}
                onOpenCommandDetails={this.onOpenCommandDetails}
                updateTabsHeight={updateTabsHeight}
              />
            </Grid>
          )}
          {showRemove && (
            <Grid item xs={12} md={md} xl={xl} className={classes[`columnOf${nbLists}`]}>
              <InterventionOperationsList
                canEdit={canEdit}
                completionStatusCanBeToggled={completionStatusCanBeToggled}
                dispatch={dispatch}
                interventionId={interventionId}
                operations={removeOperations}
                readOnly={false}
                operationsThatCanBeAdded={operationsThatCanBeAdded.removeOperations}
                operationType="remove"
                suffix={multiOutlet ? '(PDV origine)' : ''}
                updateTabsHeight={updateTabsHeight}
              />
            </Grid>
          )}
          {showMove && (
            <Grid item xs={12} md={md} xl={xl} className={classes[`columnOf${nbLists}`]}>
              <InterventionOperationsList
                canEdit={canEdit}
                completionStatusCanBeToggled={completionStatusCanBeToggled}
                dispatch={dispatch}
                interventionId={interventionId}
                operations={moveOperations}
                readOnly={false}
                operationsThatCanBeAdded={operationsThatCanBeAdded.moveOperations}
                operationType="move"
                suffix={''}
                updateTabsHeight={updateTabsHeight}
              />
            </Grid>
          )}
        </Grid>
        <InterventionOperationCommands
          open={!!openedEquipmentId}
          equipmentName={openedEquipmentName}
          onClose={this.onCloseCommandDetails}
          commands={this.getOracleCommands(openedEquipmentId)}
        />
      </>
    );
  }
}

const canUserToggleOperationCompletionStatus = (state) => {
  const intervention = state.intervention;
  if (!intervention || !intervention.intervention || !intervention.intervention.status) {
    return false;
  }
  if (!hasRole(state.currentUser, roles.intervention.process.code)) {
    return false;
  }

  return intervention.intervention.status === '_020_SCHEDULED';
};

const stateToProps = (state) => ({
  operationsThatCanBeAdded: state.interventionOperations.addableOperations,
  completionStatusCanBeToggled: canUserToggleOperationCompletionStatus(state),
  canEdit: canUserEditIntervention(state),
  oracleCommands: state.intervention.intervention.oracleCommands,
});

export default compose(withStyles(styles), connect(stateToProps))(InterventionOperations);
