import { DEMAND_IMPORTS_LOADED, DEMAND_IMPORTS_LOADING, DEMAND_IMPORTS_RESET } from '../constants/AppConstants';

const initialState = {
  loading: false,
  result: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DEMAND_IMPORTS_RESET:
      return initialState;
    case DEMAND_IMPORTS_LOADING:
      return {
        loading: true,
        result: null,
      };
    case DEMAND_IMPORTS_LOADED:
      return {
        loading: false,
        result: action.result,
      };
    default:
      return state;
  }
}
