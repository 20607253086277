import React from 'react';
import * as PropTypes from 'prop-types';
import DropZone from 'react-dropzone';
import { Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';

const styles = (theme) => ({
  dropZone: {
    borderColor: '#666',
    borderStyle: 'dashed',
    cursor: 'pointer',
    padding: theme.spacing(1),
    minHeight: 150,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: theme.typography.fontFamily,
    fontSize: '1rem',
    backgroundColor: theme.palette.background.paper,
  },
  activeDropZone: {
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
  },
  rejectDropZone: {
    borderStyle: 'solid',
    borderColor: theme.palette.error.main,
  },
  loading: {
    borderStyle: 'solid',
    '& .MuiCircularProgress-root': {
      marginRight: 16,
    },
  },
});

class DemandImportForm extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    doImport: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    addError: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
  };

  UNSAFE_componentWillMount() {
    const { reset } = this.props;
    reset();
  }

  onAdd = (files, errs) => {
    const { addError, doImport, url } = this.props;
    if (files.some((file) => file.size > 5242880)) {
      addError('Vous ne pouvez pas envoyer de fichier de taille supérieure à 5 Mo.');
      return;
    }
    if (files.length < 1) {
      if (errs.length > 0) {
        addError(`Type de fichier non reconnu : ${errs.map((file) => file.name).join(', ')}`);
      }
      return;
    }

    doImport(url, files[0]);
  };

  render() {
    const { title, loading, classes } = this.props;
    return (
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h2">{title}</Typography>
            </Grid>
            <Grid item xs={12}>
              <DropZone accept={{ 'text/csv': ['.csv'] }} multiple={false} onDrop={this.onAdd}>
                {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                  <div
                    {...getRootProps()}
                    className={classNames(classes.dropZone, {
                      [classes.activeDropZone]: isDragActive,
                      [classes.rejectDropZone]: isDragReject,
                      [classes.loading]: loading,
                    })}
                  >
                    {loading ? (
                      <>
                        <CircularProgress />
                        Import en cours
                      </>
                    ) : (
                      <>
                        <input {...getInputProps()} />
                        Déposez le fichier CSV à importer ici, ou cliquez pour sélectionner le fichier à importer.
                      </>
                    )}
                  </div>
                )}
              </DropZone>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(DemandImportForm);
