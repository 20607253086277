import React, { Component, Fragment } from 'react';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Formsy from 'formsy-react';
import SwipeableViews from 'react-swipeable-views';
import { ClipboardText, ClockOutline, Contacts } from 'mdi-material-ui';
import queryString from 'query-string';

import {
  LabelValue,
  LoadingMessage,
  ResponsiveTab,
  ResponsiveTable,
  ResponsiveTabs,
  Secured,
  TextField,
} from '../utils';
import { loadOutlet, saveOutlet } from '../../actions/OutletActions';
import { COMPANIES, CONCEPTS, CONTRACT_STATUSES, DEMAND_STATUSES, OUTLET_TYPES } from '../../constants/AppConstants';
import { hasRole } from '../../services/SecurityService';
import roles from '../../constants/roles';
import DateViewer from '../commons/DateViewer';
import history from '../../history';
import { withPageTitle } from '../../utils/page-title';
import { stickyActionBar } from '../utils/commonStyles';

const formatAddress = (address) => {
  if (!address || (!address.address1 && !address.address2 && !address.postalCode && !address.city)) {
    return '';
  }
  return [address.address1, address.address2, `${address.postalCode ?? ''} ${address.city ?? ''}`]
    .filter((line) => line)
    .join('\n');
};

const styles = (theme) => ({
  openingHoursDay: {
    width: 0,
  },
  colBorder: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      width: 1,
      right: -12,
      top: 24,
      bottom: 0,
      backgroundColor: theme.palette.divider,
    },
  },
  address: {
    whiteSpace: 'pre',
  },
  contactTitle: {
    lineHeight: 'normal',
  },
  stickyActionBar,
});

const timeIsBlank = (time) => !time || time === '00:00';

const DayOpeningHours = ({ label, morningStart, morningEnd, afternoonStart, afternoonEnd, classes }) => {
  const unset = !morningStart || !afternoonEnd;
  const fullDay = timeIsBlank(morningEnd) && timeIsBlank(afternoonStart);
  const closed = !unset && fullDay && timeIsBlank(morningStart) && timeIsBlank(afternoonEnd);
  return (
    <TableRow>
      <TableCell className={classes.openingHoursDay}>{label}</TableCell>
      {!fullDay && (
        <TableCell align="center">
          {morningStart} - {morningEnd}
        </TableCell>
      )}
      {!fullDay && (
        <TableCell align="center">
          {afternoonStart} - {afternoonEnd}
        </TableCell>
      )}
      {fullDay && (
        <TableCell align="center" colSpan={2}>
          {unset && 'Horaires indisponibles'}
          {closed && 'Fermé'}
          {!unset && !closed && `${morningStart} - ${afternoonEnd}`}
        </TableCell>
      )}
    </TableRow>
  );
};

const outletInactiveReason = (reason, outlet) =>
  ({
    UNKNOWN_STATUS: 'Salesforce ne mentionne pas de statut particulier',
    SALESFORCE_STATUS: "Le statut du contrat n'est pas considéré comme actif selon Prestago",
    BLANK_AREA: "Le secteur n'est pas renseigné",
    UNKNOWN_AREA: `Le secteur ${outlet.areaName} renseigné dans Salesforce est inconnu de Prestago`,
    BLANK_AGENCY: "Le code Oracle de la zone de vente n'est pas renseigné",
    UNKNOWN_AGENCY: `La zone de vente de code Oracle ${outlet.contractNumber} est inconnue de Prestago`,
  }[reason] || `Erreur inconnue : ${reason}`);

const OutletErrors = ({ outlet }) =>
  !outlet.active && (
    <Grid item xs={12}>
      <LabelValue text label="Erreurs">
        {outlet.inactiveReasons.map((reason) => (
          <div key={reason}>{outletInactiveReason(reason, outlet)}</div>
        ))}
      </LabelValue>
    </Grid>
  );

class Outlet extends Component {
  static propTypes = {
    outletId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    const canSeeDemands = hasRole(props.currentUser, roles.demand.view.code);

    this.state = {
      outlet: props.outlet,
      activeTabIndex: canSeeDemands ? 0 : 1,
      canSeeDemands,
    };
  }

  UNSAFE_componentWillMount() {
    const { dispatch, outletId } = this.props;
    dispatch(loadOutlet(outletId));
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ outlet: props.outlet });
  }

  onSave = () => {
    const { dispatch } = this.props;
    const { outlet } = this.state;
    dispatch(saveOutlet(outlet));
  };

  onChangeTab = (activeTabIndex) => {
    this.setState({
      activeTabIndex,
    });
  };

  onOpeningHoursChange = (event) => {
    this.setState(({ outlet }) => ({
      outlet: {
        ...outlet,
        openingHoursComment: event.target.value,
      },
    }));
  };

  onOutletContactNameChange = (event) => {
    this.setState(({ outlet }) => ({
      outlet: {
        ...outlet,
        outletContact: {
          ...outlet.outletContact,
          name: event.target.value,
        },
      },
    }));
  };

  onOutletContactPhoneChange = (event) => {
    this.setState(({ outlet }) => ({
      outlet: {
        ...outlet,
        outletContact: {
          ...outlet.outletContact,
          phone: event.target.value,
        },
      },
    }));
  };

  onPmuContactNameChange = (event) => {
    this.setState(({ outlet }) => ({
      outlet: {
        ...outlet,
        pmuContact: {
          ...outlet.pmuContact,
          name: event.target.value,
        },
      },
    }));
  };

  onPmuContactPhoneChange = (event) => {
    this.setState(({ outlet }) => ({
      outlet: {
        ...outlet,
        pmuContact: {
          ...outlet.pmuContact,
          phone: event.target.value,
        },
      },
    }));
  };

  onPmuContactFunctionChange = (event) => {
    this.setState(({ outlet }) => ({
      outlet: {
        ...outlet,
        pmuContactFunction: event.target.value,
      },
    }));
  };

  onDemandClick = (demandId) => () => {
    history.push({ pathname: `/demands/${demandId}` });
  };

  onNewDemandClick = () => {
    const { outlet } = this.state;
    history.push({
      pathname: '/demands/new',
      search: queryString.stringify({ outlet: outlet.code }),
    });
  };

  render() {
    const { outlet, activeTabIndex, canSeeDemands } = this.state;
    const { currentUser, classes } = this.props;

    if (!outlet?.id) {
      return <LoadingMessage loading>Point de vente</LoadingMessage>;
    }
    const readOnly = !hasRole(currentUser, roles.outlet.edit.code) || !outlet.active;
    const outletType = OUTLET_TYPES.getById(outlet.type || 'OUTLET');

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h1">
            <strong>{outlet.code}</strong> - {outlet.name}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} container>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h2">Informations {outletType.name.toLowerCase()}</Typography>
                </Grid>
                {outletType.hasAgency && (
                  <>
                    <Grid item xs={12}>
                      <LabelValue text label="Région">
                        {outlet.region?.name}
                      </LabelValue>
                    </Grid>
                    <Grid item xs={12}>
                      <LabelValue text label="Zone de vente">
                        {outlet.agency?.name}
                      </LabelValue>
                    </Grid>
                  </>
                )}
                {outletType.hasArea && (
                  <Grid item xs={12}>
                    <LabelValue text label="Secteur">
                      {outlet.area?.name}
                    </LabelValue>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <LabelValue text label="Adresse" classes={{ content: classes.address }}>
                    {formatAddress(outlet.address)}
                  </LabelValue>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} container>
          {outletType.id === 'AGENCY' && (
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h2">Informations Oracle</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <LabelValue text label="Code Oracle">
                      {outlet.contractNumber}
                    </LabelValue>
                  </Grid>
                  <OutletErrors outlet={outlet} />
                </Grid>
              </CardContent>
            </Card>
          )}
          {outletType.id === 'OUTLET' && (
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h2">Informations contrat</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <LabelValue text label="Numéro de contrat">
                      {outlet.contractNumber}
                    </LabelValue>
                  </Grid>
                  <Grid item xs={12}>
                    <LabelValue text label="Statut du contrat">
                      {outlet.contractStatus && CONTRACT_STATUSES.getNameById(outlet.contractStatus, () => '-')}
                    </LabelValue>
                  </Grid>
                  <Grid item xs={12}>
                    <LabelValue text label="Casaque">
                      {outlet.casaque}
                    </LabelValue>
                  </Grid>
                  <OutletErrors outlet={outlet} />
                </Grid>
              </CardContent>
            </Card>
          )}
        </Grid>

        <Grid item xs={12}>
          <Formsy>
            <Card>
              <ResponsiveTabs
                value={activeTabIndex}
                onChangeIndex={this.onChangeTab}
                variant="fullWidth"
                textColor="primary"
                indicatorColor="primary"
              >
                <ResponsiveTab icon={<ClockOutline />} label="Horaires & accès" value={0} />
                <ResponsiveTab icon={<Contacts />} label={<div>Contacts</div>} value={1} />
                {canSeeDemands && <ResponsiveTab icon={<ClipboardText />} label={<div>Demandes</div>} value={2} />}
              </ResponsiveTabs>
              <SwipeableViews index={activeTabIndex} onChangeIndex={this.onChangeTab} animateHeight>
                <div>
                  <Table>
                    <TableBody>
                      <DayOpeningHours label="Lundi" {...outlet.openingHours.monday} classes={classes} />
                      <DayOpeningHours label="Mardi" {...outlet.openingHours.tuesday} classes={classes} />
                      <DayOpeningHours label="Mercredi" {...outlet.openingHours.wednesday} classes={classes} />
                      <DayOpeningHours label="Jeudi" {...outlet.openingHours.thursday} classes={classes} />
                      <DayOpeningHours label="Vendredi" {...outlet.openingHours.friday} classes={classes} />
                      <DayOpeningHours label="Samedi" {...outlet.openingHours.saturday} classes={classes} />
                      <DayOpeningHours label="Dimanche" {...outlet.openingHours.sunday} classes={classes} />
                    </TableBody>
                  </Table>
                  <CardContent>
                    <TextField
                      label="Commentaires"
                      placeholder={readOnly ? '' : "Précisions sur les horaires et conditions d'accès"}
                      fullWidth
                      multiline
                      name="comment"
                      value={outlet.openingHoursComment}
                      disabled={readOnly}
                      onChange={this.onOpeningHoursChange}
                    />
                  </CardContent>
                </div>

                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} className={classes.colBorder}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h2">Contact PDV</Typography>
                        </Grid>

                        {outlet.phone && (
                          <Grid item xs={12}>
                            <LabelValue text label="Téléphone">
                              {outlet.phone}
                            </LabelValue>
                          </Grid>
                        )}

                        {outlet.outletContacts?.map((contact, index) => (
                          <Fragment key={contact.name}>
                            <Grid item xs={12}>
                              <Typography variant="subtitle1" className={classes.contactTitle}>
                                Contact {outlet.outletContacts.length > 1 ? index + 1 : ''}
                              </Typography>
                            </Grid>
                            {contact.name && (
                              <Grid item xs={12}>
                                <LabelValue text label="Nom & prénom">
                                  {contact.name}
                                </LabelValue>
                              </Grid>
                            )}
                            {contact.phone && (
                              <Grid item xs={12}>
                                <LabelValue text label="Téléphone">
                                  {contact.phone}
                                </LabelValue>
                              </Grid>
                            )}
                            {contact.mobilePhone && (
                              <Grid item xs={12}>
                                <LabelValue text label="Téléphone mobile">
                                  {contact.mobilePhone}
                                </LabelValue>
                              </Grid>
                            )}
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                          </Fragment>
                        ))}

                        <Grid item xs={12}>
                          <Typography variant="subtitle1" className={classes.contactTitle}>
                            Contact PDV ajouté
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Nom & prénom"
                            name="contactName"
                            fullWidth
                            value={outlet.outletContact?.name}
                            disabled={readOnly}
                            onChange={this.onOutletContactNameChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Téléphone"
                            name="contactPhone"
                            fullWidth
                            value={outlet.outletContact?.phone}
                            disabled={readOnly}
                            onChange={this.onOutletContactPhoneChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h2">Contact PMU</Typography>
                        </Grid>

                        {outlet.pmuContacts?.map((contact, index) => (
                          <Fragment key={`${contact.lastName} ${contact.firstName}`}>
                            <Grid item xs={12}>
                              <Typography variant="subtitle1" className={classes.contactTitle}>
                                Contact Secteur {outlet.pmuContacts.length > 1 ? index + 1 : ''}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <LabelValue text label="Nom & prénom">
                                {`${contact.lastName} ${contact.firstName}`}
                              </LabelValue>
                            </Grid>
                            <Grid item xs={12}>
                              <LabelValue text label="Téléphone">
                                {contact.phoneNumber}
                              </LabelValue>
                            </Grid>
                            <Grid item xs={12}>
                              <LabelValue text label="Adresse e-mail">
                                {contact.email}
                              </LabelValue>
                            </Grid>
                            <Grid item xs={12}>
                              <Divider />
                            </Grid>
                          </Fragment>
                        ))}

                        <Grid item xs={12}>
                          <Typography variant="subtitle1" className={classes.contactTitle}>
                            Contact PMU ajouté
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Fonction"
                            name="pmuContactFunction"
                            fullWidth
                            value={outlet.pmuContactFunction}
                            disabled={readOnly}
                            onChange={this.onPmuContactFunctionChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Nom & prénom"
                            name="pmuContactName"
                            fullWidth
                            value={outlet.pmuContact?.name}
                            disabled={readOnly}
                            onChange={this.onPmuContactNameChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Téléphone"
                            name="pmuContactPhone"
                            fullWidth
                            value={outlet.pmuContact?.phone}
                            disabled={readOnly}
                            onChange={this.onPmuContactPhoneChange}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>

                {canSeeDemands &&
                  (outlet.demands?.length ? (
                    <ResponsiveTable>
                      <TableHead>
                        <TableRow>
                          <TableCell>N°</TableCell>
                          <TableCell>Type</TableCell>
                          <TableCell>Concept</TableCell>
                          <TableCell>Prestataire</TableCell>
                          <TableCell>Date de validation</TableCell>
                          <TableCell>Date de planification</TableCell>
                          <TableCell>Statut</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {outlet.demands?.map((demand) => (
                          <TableRow key={demand.id} onClick={this.onDemandClick(demand.id)} hover>
                            <TableCell>{demand.number}</TableCell>
                            <TableCell>{demand.type.name}</TableCell>
                            <TableCell>{demand.concept && CONCEPTS.getById(demand.concept).name}</TableCell>
                            <TableCell>
                              {demand.subcontractor && COMPANIES.getById(demand.subcontractor).name}
                            </TableCell>
                            <TableCell>
                              {demand.validationDate && <DateViewer date={demand.validationDate} />}
                            </TableCell>
                            <TableCell>
                              {demand.operationScheduleDate && <DateViewer date={demand.operationScheduleDate} />}
                            </TableCell>
                            <TableCell>{demand.status && DEMAND_STATUSES.getById(demand.status).name}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </ResponsiveTable>
                  ) : (
                    <CardContent>
                      <Typography variant="body1">Il n'y a aucune demande pour ce point de vente.</Typography>
                    </CardContent>
                  ))}
              </SwipeableViews>
            </Card>
          </Formsy>
        </Grid>

        <Secured anyRole={[roles.demand.create.code, roles.outlet.edit.code]}>
          <Grid item xs={12} className={classes.stickyActionBar}>
            <Card>
              <CardActions>
                <Secured requiredRole={roles.demand.create.code}>
                  <Button variant="contained" disabled={!outlet.active} onClick={this.onNewDemandClick}>
                    Créer une nouvelle demande
                  </Button>
                </Secured>
                <Secured requiredRole={roles.outlet.edit.code}>
                  <Button variant="contained" color="primary" disabled={!outlet.active} onClick={this.onSave}>
                    Enregistrer
                  </Button>
                </Secured>
              </CardActions>
            </Card>
          </Grid>
        </Secured>
      </Grid>
    );
  }
}

const stateToProps = ({ outlet: { outlet }, currentUser }) => ({
  outlet,
  currentUser,
});

export default compose(
  withStyles(styles),
  connect(stateToProps),
  withPageTitle(({ outlet }) => (outlet ? `Point de vente ${outlet.code} - ${outlet.name}` : 'Point de vente')),
)(Outlet);
