import React from 'react';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import { arrayMove } from 'react-sortable-hoc';
import debugFactory from 'debug';
import { Card, CardContent, Grid, Typography } from '@mui/material';

import { loadEquipments } from '../../actions/settings/EquipmentActions';
import { loadDemandTypesIfNeeded } from '../../actions/DemandTypeActions';
import {
  deleteBundle,
  loadBundle,
  loadCompatibleOptions,
  resetBundle,
  saveOrUpdateBundle,
} from '../../actions/settings/OperationBundleActions';
import { addOperationToDemands } from '../../actions/settings/ReloadDemandCostsActions';
import { AutocompleteField, LoadingMessage, TextField } from '../utils';
import { ACTIVE_INSTALLERS, ANSWERS, CONCEPTS, COST_STRUCTURE_BUNDLE, QUESTIONS } from '../../constants/AppConstants';
import { sort, SortDirections } from '../../utils/sorting';
import EquipmentButtons from '../equipments/EquipmentButtons';
import OperationBundleOperations from './OperationBundleOperations';
import OperationBundleOptions from './OperationBundleOptions';
import { hasRole } from '../../services/SecurityService';
import roles from '../../constants/roles';
import { addMaxValidationRule, addMinValidationRule } from '../../utils/validation-rules';
import { withPageTitle } from '../../utils/page-title';
import { stickyActionBar } from '../utils/commonStyles';
import withStyles from '@mui/styles/withStyles';

const debug = debugFactory('prestago:OperationBundle');

const styles = {
  stickyActionBar,
};

class OperationBundle extends React.Component {
  static errorMessages = {
    name: {
      isDefaultRequiredValue: 'Veuillez saisir un libellé',
      isExisty: 'Veuillez saisir un libellé',
    },
    demandType: {
      isDefaultRequiredValue: 'Veuillez saisir un type de demande',
      isExisty: 'Veuillez saisir un type de demande',
    },
    concepts: {
      isDefaultRequiredValue: 'Veuillez sélectionner au moins un concept',
      isExisty: 'Veuillez sélectionner au moins un concept',
      minLength: 'Veuillez sélectionner au moins un concept',
    },
    price: {
      isDefaultRequiredValue: 'Veuillez saisir un prix',
      isExisty: 'Veuillez saisir un prix',
      min: 'Le prix minimal est 0€',
    },
    subcontractor: {
      isDefaultRequiredValue: 'Veuillez saisir un prestataire',
      isExisty: 'Veuillez saisir un prestataire',
    },
  };

  static propTypes = {
    bundle: PropTypes.object,
    bundleId: PropTypes.string,
    loading: PropTypes.bool,
    serverError: PropTypes.string,
    deletionErrors: PropTypes.array,
    validationErrors: PropTypes.array,
    validationErrorsCounter: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      // Bundle fields
      name: '',
      demandTypeId: null,
      concepts: [],
      subcontractor: null,
      price: '',
      answers: {},
      addEquipments: [],
      removeEquipments: [],
      moveEquipments: [],
      options: {
        ADD: [],
        REMOVE: [],
        MOVE: [],
      },

      // Contextual reference data
      availableConcepts: [],
      availableQuestions: [],
      compatibleEquipments: [],

      // technical fields
      editing: !props.bundleId,
      globalErrors: [],
    };
    addMinValidationRule();
    addMaxValidationRule();
  }

  componentDidMount() {
    const { dispatch, bundleId } = this.props;

    /*
     * If no id (for new bundle), then loadBundle will reset the data in Redux.
     */
    dispatch(loadBundle(bundleId));

    /*
     * Load all equipments.
     */
    dispatch(loadEquipments());

    /*
     * Load all demand types
     */
    dispatch(loadDemandTypesIfNeeded());
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { bundle: newBundle, equipments: newEquipments, demandTypes: newDemandTypes } = nextProps;
    const { bundle, equipments, demandTypes } = this.props;
    /*
     * If the bundle id changed, the form state is reset.
     */
    debug(
      'OpBundle.componentWillReceiveProps',
      demandTypes,
      '->',
      newDemandTypes,
      ' - ',
      equipments,
      '->',
      newEquipments,
    );

    let resetDependencies = false;
    if (newBundle.id !== bundle.id) {
      this.resetFormState(newBundle);
      if (newBundle.id) {
        this.setState({ editing: false });
      }
      resetDependencies = true;
    }
    if (resetDependencies || JSON.stringify(equipments) !== JSON.stringify(newEquipments)) {
      this.resetCompatibleEquipments(newEquipments, newBundle.compatibleConcepts);
    }
    if (resetDependencies || JSON.stringify(demandTypes) !== JSON.stringify(newDemandTypes)) {
      this.resetCompatibleConceptsAndQuestions(
        newEquipments,
        newDemandTypes,
        newBundle.demandTypeId,
        newBundle.compatibleConcepts,
      );
    }

    /*
     * If the server invalidates some fields, we need to update the form.
     */
    if (this.props.validationErrorsCounter !== nextProps.validationErrorsCounter) {
      const errors = {};
      const globalErrors = [];
      nextProps.validationErrors.forEach((error) => {
        if (error.field) {
          errors[error.field] = error.defaultMessage;
        } else {
          globalErrors.push(error.defaultMessage);
        }
      });
      this.setState({ globalErrors });
      this.form.current.updateInputsWithError(errors);
    }
  }

  componentWillUnmount() {
    this.props.dispatch(resetBundle());
  }

  onActivateEditingMode = () => {
    this.setState({ editing: true });
  };

  onCancel = () => {
    const { bundle, equipments } = this.props;
    this.resetFormState(bundle);
    this.setState({ editing: false });
    this.resetCompatibleEquipments(equipments, bundle.compatibleConcepts);
    this.form.current.reset();
  };

  onChangeBasic = (fieldName) => (event) => {
    const { value } = event.target;
    const newState = {};
    newState[fieldName] = value;
    this.setState(newState);
  };

  onConceptsChange = (concepts) => {
    const { equipments } = this.props;
    this.setState({
      concepts,
    });
    this.resetCompatibleEquipments(equipments, concepts);
  };

  onDemandTypeChange = (demandTypeId) => {
    const { equipments, demandTypes } = this.props;
    const { demandTypeId: oldType, concepts, subcontractor } = this.state;
    if (demandTypeId === oldType) {
      return;
    }
    this.setState({ demandTypeId });
    this.resetCompatibleConceptsAndQuestions(equipments, demandTypes, demandTypeId, concepts);
    this.resetCompatibleOptions(demandTypeId, subcontractor);
  };

  onSubcontractorChange = (subcontractor) => {
    const { demandTypeId } = this.state;
    this.setState({ subcontractor });
    this.resetCompatibleOptions(demandTypeId, subcontractor);
  };

  onAnswerChange = (question) => (answer) => {
    const { answers } = this.state;
    if (answer === answers[question]) {
      return;
    }
    const newAnswers = { ...answers };
    newAnswers[question] = answer;

    this.setState({ answers: newAnswers });
  };

  onOperationChange = (listName, index, fieldName, value) => {
    debug('onOperationChange %s %d %s -> %o', listName, index, fieldName, value);
    const newList = [...this.state[listName]];
    newList[index] = { ...newList[index] };
    newList[index][fieldName] = value;

    this.setStateField(listName, newList);
  };

  onOperationUp = (listName, index) => {
    debug('onOperationUp %s %d', listName, index);
    const newList = [...this.state[listName]];
    const tmp = newList[index];
    newList[index] = newList[index - 1];
    newList[index - 1] = tmp;

    this.setStateField(listName, newList);
  };

  onOperationDown = (listName, index) => {
    debug('onOperationUp %s %d', listName, index);
    const newList = [...this.state[listName]];
    const tmp = newList[index];
    newList[index] = newList[index + 1];
    newList[index + 1] = tmp;

    this.setStateField(listName, newList);
  };

  onOperationSort =
    (listName) =>
    ({ oldIndex, newIndex }) => {
      debug('onOperationSort %s %d %d', listName, oldIndex, newIndex);
      if (oldIndex !== newIndex) {
        this.setStateField(listName, arrayMove(this.state[listName], oldIndex, newIndex));
      }
    };

  onOperationRemove = (listName, index) => {
    debug('onOperationRemove %s %d', listName, index);
    const newList = [...this.state[listName]];
    newList.splice(index, 1);

    this.setStateField(listName, newList);
  };

  onOperationAdd = (listName) => {
    debug('onOperationAdd %s', listName);
    const newList = [...this.state[listName]];
    newList.push({
      equipmentIds: [],
      maxQuantity: null,
    });

    this.setStateField(listName, newList);
  };

  onOptionAdd = (listName) => (optionId) => {
    debug('onOptionAdd %s %s', listName, optionId);
    const { options } = this.state;
    this.setState({
      options: {
        ...options,
        [listName]: [...(options[listName] || []), optionId],
      },
    });
  };

  onOptionRemove = (listName) => (optionId) => {
    debug('onOptionRemove %s %s', listName, optionId);
    const { options } = this.state;
    this.setState({
      options: {
        ...options,
        [listName]: options[listName].filter((opt) => opt !== optionId),
      },
    });
  };

  onDelete = () => {
    const { dispatch, bundleId } = this.props;
    dispatch(deleteBundle(bundleId));
  };

  onAddToDemands = (operationType) => (operationIndex) => {
    const { dispatch, bundleId } = this.props;
    dispatch(
      addOperationToDemands({
        bundleId,
        operationType,
        operationIndex,
      }),
    );
  };

  onSave = () => {
    debug('onSave');
    this.form.current.submit();
  };

  onValidSubmit = () => {
    debug('Valid submit');

    const {
      name,
      demandTypeId,
      concepts,
      subcontractor,
      price,
      answers,
      addEquipments,
      removeEquipments,
      moveEquipments,
      options,
    } = this.state;
    const { dispatch, bundleId } = this.props;
    dispatch(
      saveOrUpdateBundle({
        id: bundleId,
        name,
        demandTypeId,
        compatibleConcepts: concepts,
        subcontractor,
        price,
        answers,
        addEquipments,
        removeEquipments,
        moveEquipments,
        options,
      }),
    );
    this.setState({ globalErrors: [] });
  };

  setStateField(fieldName, value) {
    const newState = {};
    newState[fieldName] = value;
    this.setState(newState);
  }

  getCompatibleEquipments = (equipments, selectedConcepts) => {
    if (selectedConcepts.length === 0) {
      return [];
    }

    return equipments.filter((eq) => selectedConcepts.some((concept) => eq.compatibleConcepts.indexOf(concept) >= 0));
  };

  getCompatibleConcepts = (demandType) => {
    if (!demandType) {
      return [];
    }

    return CONCEPTS.filter((c) => demandType.compatibleConcepts.includes(c.id));
  };

  getCompatibleQuestions = (demandType) => {
    if (!demandType) {
      return [];
    }

    return QUESTIONS.filter((question) => demandType.questions.includes(question.id)).map((question) => ({
      ...question,
      answers: ANSWERS.filter((answer) => question.answers.includes(answer.id)),
    }));
  };

  resetCompatibleEquipments = (equipments, concepts) => {
    this.setState({
      compatibleEquipments: this.getCompatibleEquipments(equipments, concepts),
    });
  };

  resetCompatibleConceptsAndQuestions = (equipments, demandTypes, demandTypeId, concepts) => {
    debug('resetCompatibleConceptsAndQuestions', demandTypeId, concepts);
    const demandType = demandTypes.find((t) => t.id === demandTypeId);
    const newState = {
      availableConcepts: this.getCompatibleConcepts(demandType),
      availableQuestions: this.getCompatibleQuestions(demandType),
    };
    if (!concepts.length) {
      newState.concepts = newState.availableConcepts.map((concept) => concept.id);
      this.resetCompatibleEquipments(equipments, newState.concepts);
    }
    this.setState(newState);
  };

  resetCompatibleOptions(demandTypeId, subcontractor) {
    const { dispatch } = this.props;
    if (demandTypeId && subcontractor) {
      dispatch(loadCompatibleOptions(demandTypeId, subcontractor));
    }
  }

  resetFormState(bundle) {
    const { equipments, demandTypes } = this.props;
    this.setState({
      name: bundle.name,
      demandTypeId: bundle.demandTypeId,
      concepts: bundle.compatibleConcepts,
      subcontractor: bundle.subcontractor,
      price: bundle.price,
      answers: bundle.answers,
      addEquipments: bundle.addEquipments,
      removeEquipments: bundle.removeEquipments,
      moveEquipments: bundle.moveEquipments,
      options: bundle.options,
    });
    this.resetCompatibleConceptsAndQuestions(equipments, demandTypes, bundle.demandTypeId, bundle.compatibleConcepts);
    this.resetCompatibleOptions(bundle.demandTypeId, bundle.subcontractor);
  }

  render() {
    const { deletionErrors, bundle, loading, serverError, demandTypes, canAddToDemands, compatibleOptions, classes } =
      this.props;
    const {
      compatibleEquipments,
      editing,
      name,
      demandTypeId,
      concepts,
      subcontractor,
      price,
      answers,
      addEquipments,
      removeEquipments,
      moveEquipments,
      availableConcepts,
      availableQuestions,
      options,
      globalErrors,
    } = this.state;

    if (loading || serverError) {
      return (
        <LoadingMessage loading={loading} serverError={serverError}>
          Forfait
        </LoadingMessage>
      );
    }

    const availableDemandTypes = demandTypes.filter((type) => type.costStructure === COST_STRUCTURE_BUNDLE);

    const selectableEquipments = compatibleEquipments.map((eq) => ({
      id: eq.id,
      name: eq.name,
      text: eq.name,
    }));

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h1">{name ? `Forfait "${name}"` : 'Nouveau forfait'}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Formsy noValidate ref={this.form} onValidSubmit={this.onValidSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} container>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h2">Informations générales</Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          disabled={!editing}
                          label="Libellé"
                          fullWidth
                          name="name"
                          onChange={this.onChangeBasic('name')}
                          required
                          validations="isExisty"
                          validationErrors={OperationBundle.errorMessages.name}
                          value={name}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <AutocompleteField
                          options={availableDemandTypes}
                          label="Type de demande"
                          fullWidth
                          name="demandTypeId"
                          value={demandTypeId}
                          required
                          validations="isExisty"
                          validationErrors={OperationBundle.errorMessages.demandType}
                          disabled={!editing}
                          onChange={this.onDemandTypeChange}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <AutocompleteField
                          multiple
                          options={availableConcepts}
                          disabled={!editing}
                          label="Concepts compatibles"
                          fullWidth
                          name="compatibleConcepts"
                          required
                          validations="isExisty,minLength:1"
                          validationErrors={OperationBundle.errorMessages.concepts}
                          value={concepts}
                          onChange={this.onConceptsChange}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <AutocompleteField
                          options={ACTIVE_INSTALLERS}
                          label="Prestataire"
                          fullWidth
                          name="subcontractor"
                          value={subcontractor}
                          required
                          validations="isExisty"
                          validationErrors={OperationBundle.errorMessages.subcontractor}
                          disabled={!editing}
                          onChange={this.onSubcontractorChange}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          disabled={!editing}
                          label="Prix (€)"
                          fullWidth
                          name="price"
                          onChange={this.onChangeBasic('price')}
                          required
                          type="number"
                          min="0"
                          validations="isExisty,min:0"
                          validationErrors={OperationBundle.errorMessages.price}
                          value={price}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={6} container>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h2">Questions</Typography>
                      </Grid>

                      {availableQuestions.map((question, index) => (
                        <Grid item xs={12} key={question.id}>
                          <AutocompleteField
                            options={[{ id: null, name: 'Toutes les réponses' }, ...question.answers]}
                            key={`select-${question.id}`}
                            label={question.name}
                            placeholder="Toutes les réponses"
                            fullWidth
                            name={`question-${index}`}
                            value={answers[question.id]}
                            disabled={!editing}
                            onChange={this.onAnswerChange(question.id)}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              {(editing || Boolean(addEquipments.length)) && (
                <Grid item xs={12}>
                  <OperationBundleOperations
                    title="Prestations de pose"
                    listName="addEquipments"
                    operations={addEquipments}
                    selectableEquipments={selectableEquipments}
                    editing={editing}
                    onChange={this.onOperationChange}
                    onSortEnd={this.onOperationSort('addEquipments')}
                    onRemove={this.onOperationRemove}
                    onAdd={this.onOperationAdd}
                    onAddToDemands={this.onAddToDemands('ADD')}
                    canAddToDemands={canAddToDemands}
                  />
                </Grid>
              )}

              {(editing || Boolean(removeEquipments.length)) && (
                <Grid item xs={12}>
                  <OperationBundleOperations
                    title="Prestations de dépose"
                    listName="removeEquipments"
                    operations={removeEquipments}
                    selectableEquipments={selectableEquipments}
                    editing={editing}
                    onChange={this.onOperationChange}
                    onSortEnd={this.onOperationSort('removeEquipments')}
                    onRemove={this.onOperationRemove}
                    onAdd={this.onOperationAdd}
                    onAddToDemands={this.onAddToDemands('REMOVE')}
                    canAddToDemands={canAddToDemands}
                  />
                </Grid>
              )}

              {(editing || Boolean(moveEquipments.length)) && (
                <Grid item xs={12}>
                  <OperationBundleOperations
                    title="Prestations de déplacement"
                    listName="moveEquipments"
                    operations={moveEquipments}
                    selectableEquipments={selectableEquipments}
                    editing={editing}
                    onChange={this.onOperationChange}
                    onSortEnd={this.onOperationSort('moveEquipments')}
                    onRemove={this.onOperationRemove}
                    onAdd={this.onOperationAdd}
                    onAddToDemands={this.onAddToDemands('MOVE')}
                    canAddToDemands={canAddToDemands}
                  />
                </Grid>
              )}

              {Boolean(compatibleOptions.ADD && compatibleOptions.ADD.length) && (
                <Grid item xs={12}>
                  <OperationBundleOptions
                    title="Options de pose"
                    options={compatibleOptions.ADD}
                    selected={options.ADD}
                    editing={editing}
                    onAdd={this.onOptionAdd('ADD')}
                    onRemove={this.onOptionRemove('ADD')}
                  />
                </Grid>
              )}

              {Boolean(compatibleOptions.REMOVE && compatibleOptions.REMOVE.length) && (
                <Grid item xs={12}>
                  <OperationBundleOptions
                    title="Options de dépose"
                    options={compatibleOptions.REMOVE}
                    selected={options.REMOVE}
                    editing={editing}
                    onAdd={this.onOptionAdd('REMOVE')}
                    onRemove={this.onOptionRemove('REMOVE')}
                  />
                </Grid>
              )}

              {Boolean(compatibleOptions.MOVE && compatibleOptions.MOVE.length) && (
                <Grid item xs={12}>
                  <OperationBundleOptions
                    title="Options de déplacement"
                    options={compatibleOptions.MOVE}
                    selected={options.MOVE}
                    editing={editing}
                    onAdd={this.onOptionAdd('MOVE')}
                    onRemove={this.onOptionRemove('MOVE')}
                  />
                </Grid>
              )}
            </Grid>
          </Formsy>
        </Grid>
        <Grid item xs={12} className={classes.stickyActionBar}>
          <EquipmentButtons
            deletionErrors={deletionErrors}
            globalErrors={globalErrors}
            editing={editing}
            elementId={bundle.id}
            elementName={`le forfait "${bundle.name}"`}
            onCancel={this.onCancel}
            onActivateEditingMode={this.onActivateEditingMode}
            onDelete={this.onDelete}
            onSave={this.onSave}
          />
        </Grid>
      </Grid>
    );
  }
}

const stateToProps = ({
  operationBundle: {
    bundle,
    loading,
    error,
    deletionErrors,
    validationErrors,
    validationErrorsCounter,
    compatibleOptions,
  },
  equipments: { equipments },
  demandTypes,
  currentUser,
}) => ({
  bundle,
  equipments: sort(equipments, [
    {
      field: 'name',
      direction: SortDirections.asc,
    },
  ]),
  demandTypes,
  loading,
  serverError: error,
  deletionErrors,
  validationErrors,
  validationErrorsCounter,
  compatibleOptions,
  canAddToDemands: hasRole(currentUser, roles.demand.updateCost.code),
});

export default compose(
  withStyles(styles),
  connect(stateToProps),
  withPageTitle(({ bundleId, bundle }) => (bundleId ? `Forfait ${bundle?.name ?? ''}` : 'Nouveau forfait')),
)(OperationBundle);
