import React from 'react';
import { compose } from 'recompose';

import DemandImport from '../../demands/import/DemandImport';
import securedPage from '../../securedPage';
import roles from '../../../constants/roles';
import { urls } from '../../../constants/AppConstants';
import { withPageTitle } from '../../../utils/page-title';
import { Link, Typography } from '@mui/material';

const DemandInvoicesImportPage = () => (
  <DemandImport
    title="Importer des demandes facturées"
    resultIntro={(nb) => `${nb} demande${nb > 1 ? 's' : ''} mise${nb > 1 ? 's' : ''} à jour.`}
    url={urls.demandInvoicesImport}
    instructions={
      <Typography variant="body1">
        Envoyez un fichier CSV correspondant au{' '}
        <Link href="/import/import-demandes-facturées.csv" download>
          modèle
        </Link>
        .
      </Typography>
    }
  />
);

export default compose(
  withPageTitle(() => 'Import de demandes facturées'),
  securedPage(roles.demand.invoice.code),
)(DemandInvoicesImportPage);
