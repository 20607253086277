import {
  apiBase,
  DEMAND_IMPORTS_LOADED,
  DEMAND_IMPORTS_LOADING,
  DEMAND_IMPORTS_RESET,
} from '../constants/AppConstants';
import { addGlobalError } from './SnackbarActions';
import httpService from '../services/HttpService';
import { getErrorMessage } from '../services/ErrorMessageService';

export function resetDemandImport() {
  return {
    type: DEMAND_IMPORTS_RESET,
  };
}

function updateDemandImportResult(result) {
  return {
    type: DEMAND_IMPORTS_LOADED,
    result,
  };
}

export function importDemands(url, file) {
  const fileName = file.attachmentName || file.name;
  const formData = new FormData();
  formData.append('file', file, fileName);

  return (dispatch) => {
    dispatch({ type: DEMAND_IMPORTS_LOADING });
    httpService
      .post({
        url: `${apiBase}/${url}`,
        body: formData,
        headers: {
          Accept: 'application/json',
        },
      })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((json) => Promise.reject(json));
        }
        return response.json();
      })
      .then((result) => {
        dispatch(updateDemandImportResult(result));
      })
      .catch((error) => {
        console.error('Demand import:', error);
        const message = getErrorMessage(error);
        dispatch(updateDemandImportResult(null));
        dispatch(addGlobalError(message));
      });
  };
}
