import React from 'react';
import { compose } from 'recompose';

import DemandImport from '../../demands/import/DemandImport';
import securedPage from '../../securedPage';
import roles from '../../../constants/roles';
import { urls } from '../../../constants/AppConstants';
import { withPageTitle } from '../../../utils/page-title';
import { Link, Typography } from '@mui/material';

const DemandImportPage = () => (
  <DemandImport
    title="Importer des demandes"
    resultIntro={(nb) => `${nb} demande${nb > 1 ? 's' : ''} importée${nb > 1 ? 's' : ''}.`}
    url={urls.demandImport}
    instructions={
      <>
        <Typography variant="body1">
          Envoyez un fichier CSV encodé en UTF-8, correspondant au{' '}
          <Link href="/import/import-demandes-prestago.csv" download>
            modèle
          </Link>
          .
        </Typography>
        <Typography variant="body1">
          Vous pouvez ajouter ou supprimer des colonnes de prestation, en gardant la colonne "Commentaire" à la fin. Le
          titre de chaque colonne de prestation doit contenir "Pose", "Dépose" ou "Déplacement", suivi du nom exact de
          la prestation, tel qu'il apparait dans la fiche Demande.
        </Typography>
        <Typography variant="body1">
          Chaque ligne correspond à une demande à importer. Les valeurs pour "Type de demande", "Nom Prestataire" et
          "Concept" doivent correspondre exactement aux valeurs apparaissant dans Prestago. Les valeurs dans les
          colonnes de prestation sont les quantités à appliquer. Laisser vide (ou 0) si la demande n'est pas concernée
          par la prestation.
        </Typography>
      </>
    }
  />
);

export default compose(
  withPageTitle(() => 'Import de demandes'),
  securedPage(roles.demand.import.code),
)(DemandImportPage);
