import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import SwipeableViews from 'react-swipeable-views';
import { Link as RouterLink } from 'react-router-dom';
import { FormatListChecks, History, Paperclip } from 'mdi-material-ui';
import debugFactory from 'debug';

import { AutocompleteField, LabelValue, ResponsiveTab, ResponsiveTabs, TextField, ToggleSwitch } from '../utils';
import { loadDemandTypesIfNeeded } from '../../actions/DemandTypeActions';
import { loadDemandOperations, resetDemandOperations } from '../../actions/DemandOperationsActions';
import { loadDemand, resetDemand, saveDemand, toggleDemandInvoice } from '../../actions/DemandActions';
import { displayCurrentDemandCost, estimateCost, updateCost } from '../../actions/DemandCostActions';
import { addGlobalError } from '../../actions/SnackbarActions';
import DemandOperations from './DemandOperations';
import DemandAttachments from './DemandAttachments';
import DemandInterventions from './DemandInterventions';
import DemandHistory from './DemandHistory';
import DemandButtons from './DemandButtons';
import { hasRole, visibleActiveInstallers } from '../../services/SecurityService';
import roles from '../../constants/roles';
import OperationRuleService from '../../services/OperationRuleService';
import DateViewer from '../commons/DateViewer';
import { checkDemandCoreLocked, checkDemandCanUpdateAttachments } from '../../services/WorkFlowService';
import { ANSWERS, CONCEPTS, DEMAND_STATUSES, QUESTIONS } from '../../constants/AppConstants';
import { OutletAutocompleteField } from '../commons/autocompleteFields';
import { loadOutlet } from '../commons/OutletAutocompleteField';
import { styles } from '../interventions/InterventionOperations';
import history from '../../history';
import OutletCardContent from '../outlets/OutletCardContent';
import {
  addMaxValidationRule,
  addMinValidationRule,
  addOutletExistsValidationRule,
} from '../../utils/validation-rules';
import { withPageTitle } from '../../utils/page-title';

const debug = debugFactory('prestago:Demand');

const errorMessages = {
  originOutlet: {
    isDefaultRequiredValue: "Le point de vente d'origine est obligatoire",
    matchRegexp: "Le code point de vente d'origine est constitué de chiffres",
    maxLength: "Le code point de vente d'origine fait 6 caractères maximum",
    outletExists: "Le point de vente d'origine doit exister",
  },
  type: {
    isDefaultRequiredValue: 'Le type de demande est obligatoire',
    isExisty: 'Le type de demande est obligatoire',
  },
  concept: {
    isDefaultRequiredValue: 'Le concept est obligatoire',
    isExisty: 'Le concept est obligatoire',
  },
  question: {
    isDefaultRequiredValue: 'Les questions sont obligatoires',
    isExisty: 'Les questions sont obligatoires',
  },
  subcontractor: {
    isDefaultRequiredValue: 'Le prestataire est obligatoire',
    isExisty: 'Le prestataire est obligatoire',
  },
  linkedDemandNumber: {
    isInt: 'Veuillez saisir un nombre entier',
  },
};

class Demand extends Component {
  static propTypes = {
    demandId: PropTypes.string,
    outletCode: PropTypes.string,
  };

  static defaultProps = {
    demandId: null,
    outletCode: null,
  };

  constructor(props) {
    super(props);
    const { dispatch, demandId, outletCode, currentUser } = props;

    this.form = React.createRef();

    this.state = {
      // Demand fields - flattened to be updated individually
      id: null,
      number: null,
      status: null,
      outletCode: '',
      outlet: null,
      originOutletCode: '',
      originOutlet: null,
      demandTypeId: null,
      concept: null,
      subcontractor: null,
      comment: '',
      linkedDemandNumber: null,
      linkedDemandId: null,
      invoice: false,
      answers: {},
      addOperations: null,
      removeOperations: null,
      moveOperations: null,

      // Contextual reference data
      availableConcepts: [],
      availableQuestions: [],
      outletDataSource: [],

      // technical fields
      readOnly: true,
      activeTabIndex: 0,
      operationsEdited: false,
      ruleService: null,
      coreLocked: false,

      // confirm dialog
      confirm: {
        open: false,
        message: null,
        newState: null,
      },
    };

    dispatch(loadDemandTypesIfNeeded());

    if (demandId) {
      dispatch(loadDemand(demandId));
    } else {
      debug('New demand, outlet = %s', outletCode);
      this.state.readOnly = !hasRole(currentUser, roles.demand.create.code);
    }
    dispatch(resetDemandOperations());

    addOutletExistsValidationRule();
    addMinValidationRule();
    addMaxValidationRule();
  }

  componentDidMount() {
    const { outletCode } = this.props;
    if (outletCode) {
      loadOutlet(outletCode).then((outlet) => {
        if (outlet) {
          this.setState({
            outletCode,
            outlet,
          });
        }
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    debug('componentWillReceiveProps - new props =', nextProps);
    debug('componentWillReceiveProps - old props =', this.props);
    debug('componentWillReceiveProps - state = ', this.state);
    const {
      demandId: oldId,
      demand: { demand: oldDemand, counter: oldCounter, validationErrorsCounter: oldValidationErrorsCounter },
      demandTypes: oldDemandTypes,
      demandOperations: { counter: oldOpsCounter },
      dispatch,
    } = this.props;
    const {
      demandId,
      demand: { demand, counter, validationErrorsCounter, validationErrors },
      demandTypes,
      demandOperations: { operations, counter: opsCounter },
    } = nextProps;

    // Id parameter changed - Load the demand
    if (demandId !== oldId) {
      debug('Loading demand with id %s', demandId);
      dispatch(loadDemand(demandId));
    }

    // Form validation changed
    if (oldValidationErrorsCounter !== validationErrorsCounter) {
      debug('updating validation errors %o', validationErrors);
      const errors = {};
      const globalErrors = [];
      validationErrors.forEach((error) => {
        if (error.field) {
          errors[error.field] = error.defaultMessage;
        } else {
          globalErrors.push(error.defaultMessage);
        }
      });
      this.setState({ globalErrors });
      this.form.current.updateInputsWithError(errors);
      return;
    }

    // Demand in store changed - reload the demand
    if ((demand && demand.id) !== (oldDemand && oldDemand.id)) {
      this.reloadWithDemand(demand);
      return;
    }
    if (counter !== oldCounter) {
      if ((demand && demand.invoice) !== (oldDemand && oldDemand.invoice)) {
        debug('Update invoice state to', demand.invoice);
        this.setState({ invoice: demand.invoice });
      } else {
        this.reloadWithDemand(demand);
      }
      return;
    }

    if (demandTypes.length && !oldDemandTypes.length) {
      debug('Demand types loaded');
      // Skip the reset operations stuff if the demand types were loaded late.
      return;
    }

    if (!operations) {
      // Reset the operations by default
      this.setState(
        {
          addOperations: null,
          removeOperations: null,
          moveOperations: null,
          operationsEdited: false,
        },
        this.updateTabsHeight,
      );
    } else if (opsCounter !== oldOpsCounter) {
      // Operations changed in store - reload the operations
      const moveOperations = this.withDefaultQuantities(operations.moveOperations);
      this.setState(
        {
          addOperations: this.computeAddOperations(
            this.withDefaultQuantities(operations.addOperations),
            moveOperations,
            null,
          ),
          removeOperations: this.withDefaultQuantities(operations.removeOperations),
          moveOperations,
        },
        this.updateTabsHeight,
      );
    }
  }

  // noinspection JSUnusedGlobalSymbols
  componentWillUnmount() {
    debug('componentWillUnmount');
    const { dispatch } = this.props;
    dispatch(resetDemand());
    dispatch(resetDemandOperations());
  }

  onOutletChange = (outletCode, outlet) => {
    this.setState({
      outletCode,
      outlet,
    });
  };

  onOriginOutletChange = (originOutletCode, originOutlet) => {
    this.setState({
      originOutletCode,
      originOutlet,
    });
  };

  onTypeChange = (demandTypeId) => {
    const { demandTypeId: oldType, operationsEdited } = this.state;
    const { demandTypes } = this.props;
    if (demandTypeId === oldType) {
      return;
    }
    const newState = { demandTypeId };
    if (demandTypeId) {
      const demandType = demandTypes.getById(demandTypeId);
      if (demandType && !demandType.multiOutlet) {
        newState.originOutletId = null;
        newState.originOutletCode = null;
        newState.originOutlet = null;
      }
    }
    if (operationsEdited) {
      this.setState({
        confirm: {
          open: true,
          message:
            'Vous êtes sur le point de perdre toutes vos saisies, ' +
            'confirmez-vous le changement de type de demande ?',
          newState,
        },
      });
    } else {
      this.filterAndSetState(newState);
    }
  };

  onConceptChange = (concept) => {
    debug('onConceptChange %o', concept);
    const { concept: oldConcept, operationsEdited } = this.state;
    if (concept === oldConcept) {
      return;
    }
    if (operationsEdited) {
      this.setState({
        confirm: {
          open: true,
          message: 'Vous êtes sur le point de perdre toutes vos saisies, confirmez-vous le changement de concept ?',
          newState: { concept },
        },
      });
    } else {
      this.filterAndSetState({ concept });
    }
  };

  onSubcontractorChange = (subcontractor) => {
    debug('onSubcontractorChange %o', subcontractor);
    const { subcontractor: oldSubcontractor, operationsEdited } = this.state;
    if (subcontractor === oldSubcontractor) {
      return;
    }
    if (operationsEdited) {
      this.setState({
        confirm: {
          open: true,
          message: 'Vous êtes sur le point de perdre toutes vos saisies, confirmez-vous le changement de prestataire ?',
          newState: { subcontractor },
        },
      });
    } else {
      this.filterAndSetState({ subcontractor });
    }
  };

  onAnswerChange = (question) => (answer) => {
    const { answers, operationsEdited } = this.state;
    if (answer === answers[question]) {
      return;
    }
    const newAnswers = { ...answers };
    newAnswers[question] = answer;

    if (operationsEdited) {
      this.setState({
        confirm: {
          open: true,
          message: 'Vous êtes sur le point de perdre toutes vos saisies, confirmez-vous le changement de réponse ?',
          newState: {
            answers: newAnswers,
          },
        },
      });
    } else {
      this.filterAndSetState({
        answers: newAnswers,
      });
    }
  };

  onCommentChange = (event) => {
    this.setState({ comment: event.target.value });
  };

  onLinkedNumberChange = (event) => {
    this.setState({ linkedDemandNumber: event.target.value });
  };

  onAddOperation = (operationListName, equipmentId) => {
    const newState = {};
    newState[operationListName] = this.state[operationListName].map((operation) =>
      operation.selectedEquipmentId === equipmentId
        ? {
            ...operation,
            quantity: operation.defaultQuantity,
            displayed: true,
          }
        : operation,
    );
    this.setState(newState, this.updateTabsHeight);
  };

  onChangeOperation = (operationListName, equipmentId, quantity) => {
    debug('onChangeOperation %s %s %d', operationListName, equipmentId, quantity);
    const newState = { operationsEdited: true };

    /*
     * The operation that changed
     */
    // noinspection JSUnresolvedVariable
    const previousOp = this.state[operationListName].find((op) => op.equipments.some((eq) => eq.id === equipmentId));

    newState[operationListName] = this.state[operationListName].map((operation) =>
      operation.equipments.some((eq) => eq.id === equipmentId)
        ? {
            ...operation,
            quantity,
          }
        : operation,
    );

    if (operationListName === 'addOperations' || operationListName === 'moveOperations') {
      /*
       * The operation before change is applied.
       */
      const previousOperationValue = { ...previousOp };
      newState.addOperations = this.computeAddOperations(
        newState.addOperations || this.state.addOperations,
        newState.moveOperations || this.state.moveOperations,
        previousOperationValue,
      );
    }
    this.setState(newState);
  };

  onChangeOperationGroup = (operationListName, selectedEquipmentId) => {
    debug('onChangeOperationGroup %s %s', operationListName, selectedEquipmentId);
    const newState = { operationsEdited: true };
    newState[operationListName] = this.state[operationListName].map((operation) =>
      operation.equipments.some((eq) => eq.id === selectedEquipmentId)
        ? {
            ...operation,
            selectedEquipmentId,
          }
        : operation,
    );
    this.setState(newState);
  };

  onChangeTab = (index) => {
    this.setState({
      activeTabIndex: index,
    });
  };

  onSave = () => {
    const { dispatch } = this.props;

    const demand = this.currentDemand();

    if (
      (!demand.addOperations || demand.addOperations.every((op) => !op.quantity || op.quantity === '0')) &&
      (!demand.removeOperations || demand.removeOperations.every((op) => !op.quantity || op.quantity === '0')) &&
      (!demand.moveOperations || demand.moveOperations.every((op) => !op.quantity || op.quantity === '0'))
    ) {
      dispatch(addGlobalError('Veuillez saisir au moins une prestation.'));
      return;
    }

    dispatch(saveDemand(demand));
  };

  onInvalidSubmit = () => {
    const { dispatch } = this.props;
    dispatch(addGlobalError('Le formulaire contient des erreurs'));
  };

  onEdit = () => {
    const { demandTypeId, concept } = this.state;
    const filteredLists = this.filteredLists(demandTypeId, concept);

    this.setState(
      {
        readOnly: false,
        operationsEdited: true,
        ...filteredLists,
      },
      this.updateTabsHeight,
    );
  };

  onCancel = () => {
    const {
      demand: { demand },
    } = this.props;
    const { readOnly, id } = this.state;
    if (id && !readOnly) {
      this.reloadWithDemand(demand);
    } else {
      history.goBack();
    }
  };

  onConfirmCancel = () => {
    const { confirm } = this.state;
    this.setState({
      confirm: {
        ...confirm,
        open: false,
      },
    });
  };

  onConfirmValidate = () => {
    const { confirm } = this.state;
    this.filterAndSetState({
      confirm: {
        ...confirm,
        open: false,
      },
      ...confirm.newState,
    });
  };

  onEstimateCost = () => {
    const { dispatch } = this.props;
    const { readOnly } = this.state;

    if (readOnly) {
      dispatch(displayCurrentDemandCost());
    } else {
      dispatch(estimateCost(this.currentDemand()));
    }
  };

  onUpdateCost = () => {
    const { dispatch } = this.props;
    const { id } = this.state;
    dispatch(updateCost(id));
  };

  onInvoiceChange = (invoice) => {
    const { dispatch } = this.props;
    const { id } = this.state;
    dispatch(toggleDemandInvoice(id, invoice));
    this.setState({ invoice });
  };

  currentDemand = () => {
    const {
      id,
      number,
      status,
      outlet,
      originOutlet,
      demandTypeId,
      concept,
      subcontractor,
      linkedDemandNumber,
      linkedDemandId,
      comment,
      invoice,
      answers,
      addOperations,
      removeOperations,
      moveOperations,
    } = this.state;

    return {
      id,
      number,
      status,
      outlet,
      outletId: outlet && outlet.id,
      originOutlet,
      originOutletId: originOutlet && originOutlet.id,
      demandTypeId,
      concept,
      subcontractor,
      linkedDemandNumber,
      linkedDemandId,
      comment,
      invoice,
      answers,
      addOperations,
      removeOperations,
      moveOperations,
    };
  };

  computeAddOperations = (addOperations, moveOperations, previousOperation) => {
    debug('computeAddOperations %o %o %o', addOperations, moveOperations, previousOperation);
    const { readOnly, ruleService } = this.state;
    if (readOnly) {
      return addOperations;
    }
    return ruleService ? ruleService.apply(addOperations, moveOperations, previousOperation) : addOperations;
  };

  withDefaultQuantities = (ops) =>
    ops.map((op) => ({
      ...op,
      quantity: op.displayed ? op.defaultQuantity : null,
    }));

  reloadWithDemand = (demand) => {
    debug('Reloading with demand %o', demand);

    const nextState = {
      ...demand,
      outletCode: demand.outlet ? demand.outlet.code : '',
      originOutletCode: demand.originOutlet ? demand.originOutlet.code : '',
    };
    if (demand.id) {
      nextState.readOnly = true;
      nextState.coreLocked = checkDemandCoreLocked(demand.status);
      nextState.availableConcepts = CONCEPTS;
      nextState.availableQuestions = QUESTIONS.filter((q) =>
        Object.prototype.hasOwnProperty.call(demand.answers, q.id),
      ).map((question) => ({
        ...question,
        answers: ANSWERS.filter((answer) => question.answers.includes(answer.id)),
      }));
      nextState.ruleService = new OperationRuleService(demand.demandTypeId);
    } else {
      nextState.readOnly = false;
      nextState.coreLocked = false;
    }
    this.setState(nextState, this.updateTabsHeight);
  };

  updateTabsHeight = () => {
    if (this.swipeableActions) {
      this.swipeableActions.updateHeight();
    }
  };

  filterAndSetState = (state) => {
    const { dispatch, demandTypes } = this.props;
    const {
      demandTypeId: oldTypeId,
      concept: oldConcept,
      answers: oldAnswers,
      subcontractor: oldSubcontractor,
    } = this.state;
    const { demandTypeId, concept, answers, subcontractor } = state;

    const newTypeId = demandTypeId || oldTypeId;
    let newConcept = newTypeId === oldTypeId ? concept ?? oldConcept : null;

    const newFilteredLists = this.filteredLists(newTypeId, newConcept);
    if (!newConcept && newFilteredLists.availableConcepts.length === 1) {
      newConcept = newFilteredLists.availableConcepts[0].id;
    }

    let newAnswers = newConcept === oldConcept ? answers ?? oldAnswers : null;
    const newSubcontractor = subcontractor || oldSubcontractor;

    const nextState = {
      ...state,
      ...newFilteredLists,
    };
    const { availableQuestions } = nextState;

    if (availableQuestions.length === 0) {
      // If there are no questions, consider them answered.
      newAnswers = {};
    }
    if (newTypeId !== oldTypeId) {
      nextState.concept = newConcept;
    }
    if (newConcept !== oldConcept) {
      nextState.answers = {};
    }

    this.setState(nextState);

    // Load (or reset) the available operations
    if (
      !newTypeId ||
      !newConcept ||
      !newAnswers ||
      !newSubcontractor ||
      Object.keys(newAnswers).length < availableQuestions.length ||
      Object.values(newAnswers).some((answer) => !answer)
    ) {
      // Reset the operations if all the fields are not filled
      dispatch(resetDemandOperations());
    } else {
      /*
       * All the required data has been provided by the user, compatible operations
       * are loaded from the back-end server.
       */
      debug('Loading demand operations from the back-end server');
      dispatch(loadDemandOperations(newTypeId, newConcept, newAnswers, newSubcontractor));

      const demandType = demandTypes.getById(newTypeId) || { id: newTypeId };
      this.setState({
        ruleService: new OperationRuleService(demandType),
      });
    }
  };

  filteredLists = (demandTypeId, concept) => {
    const { demandTypes } = this.props;
    const filteredLists = {
      availableConcepts: [],
      availableQuestions: [],
    };

    const demandType = demandTypes.find((t) => t.id === demandTypeId);
    if (demandType) {
      filteredLists.availableConcepts = CONCEPTS.filter((c) => demandType.compatibleConcepts.includes(c.id));

      if (concept) {
        filteredLists.availableQuestions = QUESTIONS.filter((question) =>
          demandType.questions.includes(question.id),
        ).map((question) => ({
          ...question,
          answers: ANSWERS.filter((answer) => question.answers.includes(answer.id)),
        }));
      }
    }

    return filteredLists;
  };

  render() {
    const {
      id,
      number,
      status,
      outletCode,
      outlet,
      originOutletCode,
      originOutlet,
      demandTypeId,
      concept,
      subcontractor,
      linkedDemandNumber,
      linkedDemandId,
      comment,
      answers,
      addOperations,
      removeOperations,
      moveOperations,
      availableConcepts,
      availableQuestions,
      activeTabIndex,
      confirm,
      readOnly,
      coreLocked,
      invoice,
    } = this.state;
    const {
      demandTypes,
      currentUser,
      demand: {
        demand: {
          events,
          interventions,
          operationScheduleDate,
          validationDate,
          demandTypeId: originalDemandTypeId,
          demandTypeName,
        },
      },
      classes,
    } = this.props;

    const demandType = demandTypes.getById(demandTypeId);
    const multiOutlet = demandType && demandType.multiOutlet;

    const selectableDemandTypes =
      !originalDemandTypeId || demandTypes.getById(originalDemandTypeId)
        ? demandTypes
        : [
            ...demandTypes,
            {
              id: originalDemandTypeId,
              name: demandTypeName,
            },
          ];

    let operations;
    if (!addOperations) {
      operations = (
        <CardContent>
          <Typography variant="body1">
            Veuillez saisir le type de demande et le concept, et répondre à toutes les questions.
          </Typography>
        </CardContent>
      );
    } else if (!addOperations.length && !removeOperations.length && !moveOperations.length) {
      operations = (
        <CardContent>
          <Typography variant="body1">Aucune prestation disponible pour les critères renseignés.</Typography>
        </CardContent>
      );
    } else {
      const showAdd =
        addOperations.length > 0 && (!readOnly || addOperations.some((op) => op.displayed && op.quantity > 0));
      const showRemove =
        removeOperations.length > 0 && (!readOnly || removeOperations.some((op) => op.displayed && op.quantity > 0));
      const showMove =
        moveOperations.length > 0 && (!readOnly || moveOperations.some((op) => op.displayed && op.quantity > 0));
      const nbLists = showAdd + showRemove + showMove;
      const md = nbLists === 3 ? 12 : 6; // Si 3 listes, on se rabat sur 1 colonne, sinon 2.
      const xl = nbLists === 3 ? 4 : 6; // 2 ou 3 colonnes, jamais une seule

      operations = (
        <Grid container>
          {showAdd && (
            <Grid item xs={12} md={md} xl={xl} className={classes[`columnOf${nbLists}`]}>
              <DemandOperations
                demandId={id}
                demandStatus={status}
                title={`Prestations de pose ${multiOutlet ? '(PDV destination)' : ''}`}
                operations={addOperations}
                readOnly={readOnly}
                onChangeOperation={(equipmentId, value) => this.onChangeOperation('addOperations', equipmentId, value)}
                onAddOperation={(equipmentId) => this.onAddOperation('addOperations', equipmentId)}
                onChangeOperationGroup={(equipmentId) => this.onChangeOperationGroup('addOperations', equipmentId)}
                updateTabsHeight={this.updateTabsHeight}
              />
            </Grid>
          )}
          {showRemove && (
            <Grid item xs={12} md={md} xl={xl} className={classes[`columnOf${nbLists}`]}>
              <DemandOperations
                demandId={id}
                demandStatus={status}
                title={`Prestations de dépose ${multiOutlet ? '(PDV origine)' : ''}`}
                operations={removeOperations}
                readOnly={readOnly}
                onChangeOperation={(equipmentId, value) =>
                  this.onChangeOperation('removeOperations', equipmentId, value)
                }
                onAddOperation={(equipmentId) => this.onAddOperation('removeOperations', equipmentId)}
                onChangeOperationGroup={(equipmentId) => this.onChangeOperationGroup('removeOperations', equipmentId)}
                updateTabsHeight={this.updateTabsHeight}
              />
            </Grid>
          )}
          {showMove && (
            <Grid item xs={12} md={md} xl={xl} className={classes[`columnOf${nbLists}`]}>
              <DemandOperations
                demandId={id}
                demandStatus={status}
                title="Prestations de déplacement"
                operations={moveOperations}
                readOnly={readOnly}
                onChangeOperation={(equipmentId, value) => this.onChangeOperation('moveOperations', equipmentId, value)}
                onAddOperation={(equipmentId) => this.onAddOperation('moveOperations', equipmentId)}
                onChangeOperationGroup={(equipmentId) => this.onChangeOperationGroup('moveOperations', equipmentId)}
                updateTabsHeight={this.updateTabsHeight}
              />
            </Grid>
          )}
        </Grid>
      );
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h1">
            {id ? `${outlet ? `${outlet.code} - ${outlet.name} - ` : ''} Demande ${number}` : 'Nouvelle demande'}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Formsy onValidSubmit={this.onSave} onInvalidSubmit={this.onInvalidSubmit} noValidate ref={this.form}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={multiOutlet ? 6 : 4} lg={multiOutlet && 3} container>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h2">Informations générales</Typography>
                      </Grid>
                      {id && (
                        <Grid item xs={12}>
                          <LabelValue label="Statut">{DEMAND_STATUSES.getNameById(status)}</LabelValue>
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <AutocompleteField
                          options={selectableDemandTypes}
                          label="Type"
                          fullWidth
                          name="type"
                          value={demandTypeId || null}
                          required
                          validations="isExisty"
                          validationErrors={errorMessages.type}
                          disabled={readOnly || coreLocked}
                          onChange={this.onTypeChange}
                        />
                      </Grid>
                      {demandTypeId && (
                        <Grid item xs={12}>
                          <AutocompleteField
                            options={availableConcepts}
                            label="Concept"
                            fullWidth
                            name="concept"
                            value={concept || null}
                            required
                            validations="isExisty"
                            validationErrors={errorMessages.concept}
                            disabled={readOnly || coreLocked}
                            onChange={this.onConceptChange}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <AutocompleteField
                          options={visibleActiveInstallers(currentUser)}
                          label="Prestataire"
                          fullWidth
                          name="subcontractor"
                          value={subcontractor || null}
                          required
                          validations="isExisty"
                          validationErrors={errorMessages.subcontractor}
                          disabled={readOnly || coreLocked}
                          onChange={this.onSubcontractorChange}
                        />
                      </Grid>
                      {id && operationScheduleDate && (
                        <Grid item xs={12}>
                          <LabelValue label="Date de planification">
                            <DateViewer date={operationScheduleDate} />
                          </LabelValue>
                        </Grid>
                      )}
                      {id && validationDate && (
                        <Grid item xs={12}>
                          <LabelValue label="Date de validation">
                            <DateViewer date={validationDate} />
                          </LabelValue>
                        </Grid>
                      )}
                      {readOnly ? (
                        linkedDemandNumber && (
                          <Grid item xs={12}>
                            <LabelValue label="Demande liée">
                              <Link component={RouterLink} to={`/demands/${linkedDemandId}`}>
                                {linkedDemandNumber}
                              </Link>
                            </LabelValue>
                          </Grid>
                        )
                      ) : (
                        <Grid item xs={12}>
                          <TextField
                            label="Demande liée"
                            placeholder="Numéro de la demande liée"
                            fullWidth
                            name="linkedDemandNumber"
                            value={linkedDemandNumber}
                            validations="isInt"
                            validationErrors={errorMessages.linkedDemandNumber}
                            onChange={this.onLinkedNumberChange}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <TextField
                          label="Commentaires"
                          placeholder={readOnly ? '' : "Exemple : pose d'une borne"}
                          fullWidth
                          multiline
                          name="comment"
                          value={comment}
                          disabled={readOnly}
                          onChange={this.onCommentChange}
                        />
                      </Grid>
                      {id && status >= '_040_OPERATIONS_PROCESSING' && (
                        <Grid item xs={12}>
                          <ToggleSwitch
                            disabled={!hasRole(currentUser, roles.demand.invoice.code)}
                            label="Facturée"
                            value={invoice}
                            onChange={this.onInvoiceChange}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} sm={multiOutlet ? 6 : 4} lg={multiOutlet && 3} container>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h2">Questions</Typography>
                      </Grid>

                      {availableQuestions.map((question, index) => (
                        <Grid item xs={12} key={question.id}>
                          <AutocompleteField
                            options={question.answers}
                            label={question.name}
                            fullWidth
                            name={`question-${index}`}
                            value={answers[question.id] || null}
                            required
                            validations="isExisty"
                            validationErrors={errorMessages.question}
                            disabled={readOnly || coreLocked}
                            onChange={this.onAnswerChange(question.id)}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} sm={multiOutlet ? 6 : 4} lg={multiOutlet && 3} container>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <OutletCardContent
                        onOutletChange={this.onOutletChange}
                        multiOutlet={multiOutlet}
                        editing={!readOnly && !coreLocked}
                        outletCode={outletCode}
                        outlet={outlet}
                      />
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              {multiOutlet && (
                <Grid item xs={12} sm={6} lg={3} container>
                  <Card>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h2">Point de vente Origine</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {readOnly || coreLocked ? (
                            <LabelValue label="Code PDV Origine">{originOutletCode}</LabelValue>
                          ) : (
                            <OutletAutocompleteField
                              label="Code PDV Origine"
                              fullWidth
                              required
                              name="originOutletCode"
                              value={originOutletCode}
                              onChange={this.onOriginOutletChange}
                              getOptionValue={({ code }) => code}
                              validations={{
                                outletExists: outlet,
                              }}
                              validationErrors={errorMessages.outlet}
                            />
                          )}
                        </Grid>
                        {originOutlet && (
                          <>
                            <Grid item xs={12}>
                              <LabelValue label="Enseigne">
                                {hasRole(currentUser, roles.outlet.view.code) ? (
                                  <Link component={RouterLink} to={`/outlets/${originOutlet.id}`}>
                                    {originOutlet.name}
                                  </Link>
                                ) : (
                                  originOutlet.name
                                )}
                              </LabelValue>
                            </Grid>
                            <Grid item xs={12}>
                              <LabelValue label="Région">{originOutlet.region && originOutlet.region.name}</LabelValue>
                            </Grid>
                            <Grid item xs={12}>
                              <LabelValue label="Zone de vente">
                                {originOutlet.agency && originOutlet.agency.name}
                              </LabelValue>
                            </Grid>
                            {originOutlet.area && originOutlet.area.name && (
                              <Grid item xs={12}>
                                <LabelValue label="Secteur">{originOutlet.area && originOutlet.area.name}</LabelValue>
                              </Grid>
                            )}
                          </>
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              )}

              <Grid item xs={12}>
                <Card>
                  <ResponsiveTabs
                    value={activeTabIndex}
                    onChangeIndex={this.onChangeTab}
                    variant="fullWidth"
                    textColor="primary"
                    indicatorColor="primary"
                  >
                    <ResponsiveTab icon={<FormatListChecks />} label="Prestations" value={0} />
                    <ResponsiveTab icon={<Paperclip />} label="Pièces jointes" value={1} />
                    <ResponsiveTab icon={<History />} label="Historique" value={2} />
                  </ResponsiveTabs>
                  <SwipeableViews
                    index={activeTabIndex}
                    onChangeIndex={this.onChangeTab}
                    animateHeight
                    action={(actions) => {
                      this.swipeableActions = actions;
                    }}
                  >
                    <div>{operations}</div>

                    <CardContent>
                      {id ? (
                        <DemandAttachments
                          readOnly={!checkDemandCanUpdateAttachments(currentUser, status)}
                          onAttachmentsChange={() => this.onChangeTab(activeTabIndex)}
                        />
                      ) : (
                        <Typography variant="body1">
                          Veuillez enregistrer votre demande pour pouvoir gérer les pièces jointes.
                        </Typography>
                      )}
                    </CardContent>

                    <div>
                      <CardContent>
                        <Typography variant="h2">Interventions</Typography>
                      </CardContent>
                      <DemandInterventions interventions={interventions} />
                      <CardContent>
                        <Typography variant="h2">Historique des statuts</Typography>
                      </CardContent>
                      <DemandHistory events={events} />
                    </div>
                  </SwipeableViews>
                </Card>
              </Grid>
            </Grid>
          </Formsy>
        </Grid>

        <Grid item xs={12} className={classes.stickyActionBar}>
          <DemandButtons
            demandId={this.state.id}
            demandNumber={this.state.number}
            demandStatus={this.state.status}
            onDemandFormCancel={this.onCancel}
            onDemandFormEdit={this.onEdit}
            onDemandFormSubmit={() => this.form.current.submit()}
            onDemandEstimateCost={this.onEstimateCost}
            onDemandUpdateCost={this.onUpdateCost}
            readOnly={readOnly}
          />
        </Grid>

        <Dialog open={confirm.open} onClose={() => this.onConfirmCancel()}>
          <DialogContent>
            <DialogContentText>{confirm.message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onConfirmCancel}>Annuler</Button>
            <Button color="primary" onClick={this.onConfirmValidate}>
              Confirmer
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

const stateToProps = (state) => ({
  demand: state.demand,
  demandOperations: state.demandOperations,
  demandTypes: state.demandTypes,
  currentUser: state.currentUser,
});

export default compose(
  withStyles(styles),
  connect(stateToProps),
  withPageTitle(({ demandId, demand: { demand } }) =>
    demandId ? `Demande ${demand?.number ?? ''}` : 'Nouvelle demande',
  ),
)(Demand);
